import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getToolsConfig, resetConfig, updateToolsConfig } from "../../api/toolsConfig/toolsConfigAPI"
import LoaderCircle from "../../utils/LoaderCircle"
import { SVGIconsList } from "../../utils/SVGIcons"
import { cn } from "../../utils/clsx"
import LeftPanelHeader from "../research/LeftPanelHeader"

type ToolsListTypes = {
    action_items: string
    description: string
    image_url?: string
    is_disabled: boolean
    items: {
        item_id?: string
        item_hint?: string
        item_name: string
        item_content_type: string
    }[]
    query_prompt: string
    report_prompt: string
    report_purpose: string
    tool_id: string
    tool_name: string
    total_words: number
}

const fieldsToRender = ["report_purpose", "report_prompt", "total_words"]
const ToolsConfigPage = () => {
    const navigate = useNavigate()
    const [selectedTool, setSelectedTool] = useState<string>("")
    const [toolsList, setToolsList] = useState<ToolsListTypes[]>([])

    const getToolsData = async () => {
        setToolsList([])
        const response = await getToolsConfig()
        setToolsList(response?.tools?.tools)
    }
    const handleUpdateTools = async (tool_id: string, data: any) => {
        let tempToolsData = JSON.parse(JSON.stringify(toolsList))
        tempToolsData = tempToolsData.map((i: ToolsListTypes) => {
            if (i.tool_id === selectedTool) {
                return { ...i, ...data }
            }
            return i
        })
        const response = await updateToolsConfig({ tools: tempToolsData })
        getToolsData()
    }

    const handleResetConfig = async () => {
        await resetConfig()
        await getToolsData()
    }

    useEffect(() => {
        if (localStorage.getItem("user-role") !== "admin") {
            navigate("/research")
        }
        getToolsData()
    }, [])

    if (!toolsList || toolsList.length === 0) {
        return (
            <div className="w-full h-40 flex justify-center items-center">
                <LoaderCircle />
            </div>
        )
    }

    return (
        <div className="w-full h-full flex flex-row gap-x-2 overflow-hidden">
            <div className="w-[27%] h-full p-2 dark:bg-brand-darkGray bg-white/20 border rounded-2xl">
                <div className="h-full flex flex-col">
                    {/* This is the header part, this will be static */}
                    <div className="h-fit w-full p-4 pb-2 pt-2 space-y-10 top-0 sticky dark:bg-brand-darkGray bg-transparent z-10">
                        <LeftPanelHeader className="px-2 py-0" />
                    </div>
                    <div className="my-8 divide-y border-y">
                        {toolsList
                            .filter((i) => !i.is_disabled)
                            .map((i) => (
                                <div
                                    key={i.tool_id}
                                    className={cn("h-20 cursor-pointer dark:hover:bg-background hover:bg-white/20 flex items-center", {
                                        "bg-opacity-10 !border-transparent border-solid dark:bg-background bg-white/20":
                                            selectedTool === i.tool_id,
                                    })}
                                    onClick={() => setSelectedTool(i.tool_id)}
                                >
                                    <span className="p-6 text-xl font-normal uppercase dark:text-white text-black/70">{i.tool_name} </span>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <div className="flex-1 h-full flex flex-col bg-primary border rounded-2xl overflow-hidden">
                <div className="w-full min-h-20 border-b-1 border-white dark:bg-brand-darkGray bg-white/20">
                    <div className="w-full h-full px-8 flex justify-between items-center dark:text-white text-black/70">
                        <div className="flex flex-row items-center gap-3">
                            <button className="flex items-center outline-none focus:outline-none" onClick={() => setSelectedTool("")}>
                                <SVGIconsList.Chevron className="dark:fill-white fill-black/70" />
                            </button>
                            <span className="text-[26px] font-light pl-5">Change your tools config here.</span>
                        </div>
                        <div className="w-fit flex flex-row items-center gap-x-8">
                            <span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground dark:bg-transparent bg-white">
                                <span>KNOW ME</span>
                            </span>
                            <span>
                                <SVGIconsList.BellIcon className="dark:fill-white fill-black/70" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="h-full flex flex-col overflow-y-scroll dark:bg-transparent bg-white">
                    {selectedTool.length > 0 ? (
                        <div className="mt-12 mx-4 flex justify-between items-end space-x-4">
                            <IndividualToolConfig
                                toolData={toolsList.find((i) => i.tool_id === selectedTool)!}
                                selectedTool={selectedTool}
                                updateData={handleUpdateTools}
                                handleResetConfig={handleResetConfig}
                            />
                        </div>
                    ) : (
                        <div className="w-full h-48 flex items-center justify-center">
                            <span className="text-2xl font-light dark:text-white/70 text-black/50">Select a Tool from left panel to edit the config.</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const IndividualToolConfig = ({
    toolData,
    selectedTool,
    updateData,
    handleResetConfig,
}: {
    toolData: ToolsListTypes
    selectedTool: string
    updateData: (toolId: string, data: any) => void
    handleResetConfig: () => void
}) => {
    const [formValues, setFormValues] = useState<ToolsListTypes>(toolData)

    useEffect(() => {
        setFormValues(toolData)
    }, [toolData])

    const handleUpdateData = () => {
        updateData(toolData.tool_id, formValues)
    }

    function textAreaAdjust(id: string) {
        var element = document.getElementById(id)
        if (!element) return
        element.style.height = "1px"
        element.style.height = 25 + element.scrollHeight + "px"
    }

    useEffect(() => {
        fieldsToRender.map(i => i !== "total_words" && textAreaAdjust(i))
    }, [toolData])

    return (
        <div className="w-full mx-4 flex flex-col justify-between">
            <div className="w-full flex gap-x-3 mb-6 text-2xl font-light dark:text-white/70 text-black/70">
                <label htmlFor="description">{toolData.tool_name}: </label>
                <p id="description">{toolData.description}</p>
            </div>
            <form
                className="w-full flex flex-wrap items-center gap-5"
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    handleUpdateData()
                }}
                onReset={(e) => {
                    e.preventDefault()
                    handleResetConfig()
                }}
            >
                <div className="w-full flex justify-end">
                    <button className="w-48 h-14 right-0 text-lg rounded-full border-[1px] dark:border-white border-black/50 hover:bg-brand-gradient dark:text-white/70 text-black/70 hover:text-white/70" type="reset">
                        <span className="uppercase">Reset</span>
                    </button>
                </div>
                {fieldsToRender.map((i) => (
                    <div className="w-full flex flex-col justify-between items-start gap-y-2 dark:text-white/70 text-black/70" key={i}>
                        <label htmlFor={i} className="font-light capitalize text-wrap break-words text-xl">
                            {i.split("_").join(" ")}:
                        </label>
                        {i === "total_words" ? (
                            <input
                                id={i}
                                className="w-full h-12 px-4 bg-transparent border"
                                type="number"
                                value={formValues?.[i]}
                                //@ts-ignore
                                onChange={(e) => setFormValues((prev) => ({ ...prev, [i]: Number(e.target.value) }))}
                            />
                        ) : (
                            <textarea
                                id={i}
                                onFocus={() => textAreaAdjust(i)}
                                className="w-full h-fit outline-none bg-transparent border p-2 overflow-y-scroll"
                                //@ts-ignore
                                value={formValues?.[i]}
                                onChange={(e) => setFormValues((prev) => ({ ...prev, [i]: e.target.value }))}
                            // required
                            />
                        )}
                    </div>
                ))}
                <button className="w-48 h-14 self-end text-lg rounded-full border-[1px] dark:border-white border-black/50 hover:bg-brand-gradient dark:text-white/70 text-black/70 hover:text-white/70" type="submit">
                    <span className="uppercase">Update</span>
                </button>
            </form>
        </div>
    )
}

export default ToolsConfigPage
