import { useEffect, useState } from "react";
import { SVGIconsList } from "../../utils/SVGIcons";

import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { deleteNode, listNodes, readNode } from "../../api/document/document";
import { DocumentDataType } from "../../types/document";
import CheckboxComponent from "../../utils/CheckboxComponent";
import LoaderCircle from "../../utils/LoaderCircle";
import TableComponent, { Column } from "../../utils/TableComponent";
import { Dropdown, MenuProps, Modal, notification } from "antd";
import DragDropUpload from "../../utils/DNDUploadComponent";


const UploadComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams()

	return (
		<div className="flex-1 flex flex-col w-full h-full rounded-2xl overflow-hidden">
			{/* <div className="w-full min-h-20 border-b-1 border-white dark:bg-brand-darkGray bg-slate-100">
				<div className="w-full h-full px-8 flex justify-between items-center">
					<span className="text-[26px] font-light dark:text-white text-black/70">Upload Document</span>
					<div className="w-fit flex flex-row items-center gap-x-8">
						<span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground dark:bg-transparent bg-white dark:text-white text-black/70">
							<span>KNOW ME</span>
						</span>
						<span>
							<SVGIconsList.BellIcon className="dark:fill-white fill-black/70" />
						</span>
					</div>
				</div>
			</div> */}
			<div className="document-files-content dark:bg-background bg-white w-full h-[calc(100%-5rem)]">
				<div className="content-header flex flex-row pb-6 px-8 justify-between items-center">
					<span className="text-[22px] font-light dark:text-white text-black/70">Upload Documents</span>
					{/* <div className="flex flex-row gap-x-6 items-center">
						<SVGIconsList.SearchIcon className="fill-white" />
						|
						<SVGIconsList.LeftAlignedMenu className="fill-white" />
					</div> */}
				</div>
				<div className="dashboard-row flex flex-row items-center gap-3 h-[calc(100%-2.5rem)] px-5 pb-5 flex-wrap">
                    <div className="mx-auto w-full h-full">
                        <DragDropUpload 
                            folderId={searchParams.get('folder_id') || ''}
                            maxFileSize={10485760} 
                            chunked={true} /> {/* 10MB */}
                    </div>
				</div>
			</div>
		</div>
	);
};

export default UploadComponent;
