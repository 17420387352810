import axios, { AxiosRequestConfig } from "axios"
import { API_ENDPOINTS } from "../../utils/apiURL"
import { notification } from "antd"

export const fetchMessages = async (threadId: string, controller: AbortController) => {
    let url = `${API_ENDPOINTS.getMessages}${threadId}`

    return await axios
        .get(url, { signal: controller.signal })
        .then((response) => response.data)
        .catch((err) => console.log(err))
}

export const updateMessage = async ({ message_id, text }: { message_id: string; text: string }) => {
    let payload = { markdown_text: text }
    let config = {
        method: "PUT",
        url: `${API_ENDPOINTS.getMessages}${message_id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}

export const downloadToPDF = async (value: string, docType: string, documentTitle: string) => {
    notification.info({ message: "Please wait, your file is being downloaded" });
    let payload = {
        markdown_text: value,
    }
    // let url = `${API_ENDPOINTS.baseUrl}/download_markdown`
    const config: AxiosRequestConfig = {
        url: `${API_ENDPOINTS.baseUrl}/download_markdown`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
        responseType: "blob",
    }
    axios(config).then((resp) => {
        console.log(resp?.data);
        const url = URL.createObjectURL(resp?.data)
        const a = document.createElement("a")
        a.href = url
        a.download = documentTitle || "download.pdf";
        document.body.appendChild(a)
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
    }).catch((e) => {
        console.error(e);
        notification.error({ message: "Something went wrong while downloading the file." })
    })
}
