import { Button, Dropdown, Form, Input, MenuProps, Modal, Radio, Tooltip, notification } from "antd"
import { format } from "date-fns"
import { useContext, useState } from "react"
import { downloadToPDF } from "../../../api/research/messagesAPI"
import { ResearchContext } from "../../../store/ResearchContext"
import { MessageTypes } from "../../../types/research/messageTypes"
import { SVGIconsList } from "../../../utils/SVGIcons"
import { cn } from "../../../utils/clsx"
import { ToolsList } from "../../../utils/toolsList"
import { ThreadType } from "../../../types/research/threadTypes"
import { useSearchParams } from "react-router-dom"
import { createNode } from "../../../api/document/document"

type Props = {
    message: MessageTypes
}

const Message = (props: Props) => {
    const { message } = props
    const messageDate = new Date(message.date_time)
    const formattedTime = format(messageDate, "hh:mm aa")
    const { selectedThread } = useContext(ResearchContext)

    const threadContext = () => {
        const currentTools = ToolsList.find((tool) => tool?.tool_name === selectedThread?.type)
        return <table className="border-separate [border-spacing:10px] w-80">
            {currentTools?.items?.map((toolItem) => {
                const itemText = message?.context?.[toolItem?.item_id as keyof ThreadType['context']];
                return <tr>
                    <td className="whitespace-nowrap">{toolItem?.item_name}</td>
                    <td style={itemText?.length > 40 ? { minWidth: "40ch" } : {}}>{itemText}</td>
                </tr>
            })}
        </table>
    }

    return (
        <div className="message-container">
            <div
                className={cn("flex items-end", {
                    "justify-end": message.sender_type === 1,
                })}
            >
                <div
                    className={cn("w-14 h-14 border border-white bg-opacity-15 rounded-full overflow-hidden flex flex-row items-center justify-center", {
                        "order-1 border border-[#BBBBBB] pl-1 rounded-br-none bg-brand-gradient": message.sender_type === 2,
                        "order-2 rounded-bl-none bg-brand-gradient-zuno": message.sender_type === 1,
                        invisible: false,
                    })}
                >
                    {message.sender_type === 1 ? (
                        <span title={localStorage.getItem("username") || ""} className="uppercase text-2xl">
                            {localStorage.getItem("username")?.slice(0, 2)}
                        </span>
                    ) : (
                        <img src="/robot.svg" alt="User_Icon" width={40} height={40} />
                    )}
                </div>
                <div
                    className={cn("max-w-xl min-h-12 mx-2 flex flex-col space-y-2 rounded-md overflow-hidden", {
                        "order-1 items-end bg-brand-gradient-zuno-light": message.sender_type === 1,
                        "order-2 items-start bg-brand-gradient": message.sender_type === 2,
                    })}
                >
                    {message.sender_type === 2 ? (
                        message?.markdown_text?.length === 0 ?
                            // If markdown_text is empty then it's a message, else it's a file
                            <div
                                className={cn("w-full px-4 py-2 text-white text-wrap break-words", "rounded-bl-none")}
                            >
                                <div className="text-white text-left flex flex-row items-start gap-x-2">
                                    <p className="font-light leading-7">{message.text}</p>
                                </div>
                                <div className="text-xs select-none mt-2 w-full text-right">{formattedTime}</div>
                            </div>
                            :
                            <div className={cn("w-full px-4 py-2 text-white text-wrap break-words", { "rounded-bl-none": message.sender_type === 2 })}>
                                <FilePreview message={message} />
                                <div className="text-xs select-none mt-2 w-full text-right">{formattedTime}</div>
                            </div>
                    ) : (
                        <div
                            className={cn("w-full px-4 py-2 text-white text-wrap break-words", {
                                "rounded-br-none": message.sender_type === 1,
                                "rounded-bl-none": message.sender_type === 2,
                            })}
                        >
                            <div className="text-white text-left flex flex-row items-start gap-x-2">
                                <p className="font-light leading-7">{message.text}</p>
                                {
                                    String(message?.context?.["flow"]).toLowerCase() === "assistant" ? <></> :
                                        <Tooltip title={threadContext} placement="left" overlayClassName="max-w-max msg-context-info-tooltip-overlay" overlayInnerStyle={{ border: "1px solid", borderImage: "linear-gradient(134.94deg, #F24C51 4.5%, #BC57E3 94.17%) 1" }}>
                                            <span>
                                                <SVGIconsList.InfoIcon className="text-brand-skyBlue [flex-basis:24px]" />
                                            </span>
                                        </Tooltip>
                                }
                            </div>
                            <div className="text-xs select-none mt-2 w-full text-right">{formattedTime}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const FilePreview = ({ message }: { message: MessageTypes }) => {
    const { setViewMarkDown, setSelectedMessage, selectedThread } = useContext(ResearchContext)
    const [showModal, setShowModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDocumentPublishing, setIsDocumentPublishing] = useState(false);

    const handleViewMarkDown = () => {
        setViewMarkDown(true)
        setSelectedMessage(message)
    }
    // const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    //     switch (key) {
    //         case 'pdf':
    //             downloadToPDF(message.markdown_text, key, selectedThread?.title)
    //             break;
    //         case "publish":
    //             setShowModal(true);
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // const downloadOptions = [
    //     {
    //         key: "pdf",
    //         label: (
    //             <div className="flex gap-x-3 items-center py-2">
    //                 <SVGIconsList.PdfIcon /> <span>Download as PDF</span>
    //             </div>
    //         ),
    //     },
    //     {
    //         key: "publish",
    //         label: (
    //             <div className="flex gap-x-3 items-center py-2">
    //                 <SVGIconsList.PublishDocument /> <span>Publish as PDF</span>
    //             </div>
    //         ),
    //     },
    // {
    //     key: "doc",
    //     label: (
    //         <div className="flex gap-x-3">
    //             <SVGIconsList.DocxIcon /> <span>Download as DOC</span>
    //         </div>
    //     ),
    //     disabled: true,
    // },
    // ]

    const handlePublishDocument = (formValues: any) => {
        setIsDocumentPublishing(true);
        const payload = {
            ...formValues,
            parent_name: ToolsList.find((e) => e?.tool_name === selectedThread?.type)?.tool_id,
            org_name: localStorage.getItem("org-id"),
            doc_type: "document",
            content: message?.markdown_text || "",
            thread_id: searchParams.get("thread_id"),
            username: localStorage.getItem("username"),
        }
        createNode(payload).then((response) => {
            notification.success({ message: "File Published Successfully" });
            setShowModal(false);
        }).catch((error) => {
            console.error(error);
            notification.error({ message: error?.response?.message || "Something went wrong" });
        }).finally(() => {
            setIsDocumentPublishing(false)
        })
    }

    return (
        <div className="w-72 h-28 p-3 bg-card-hover-background bg-cover bg-center">
            <div className="h-full flex flex-col gap-y-3">
                <div className="h-2/3 p-2 flex gap-x-3 items-center bg-white/15">
                    <SVGIconsList.Document className="fill-white" />
                    <span title={selectedThread?.title} className="font-normal overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {selectedThread?.title || "Document"}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <button className="w-27 h-8 px-6 border border-[#BBBBBB] rounded-md" onClick={handleViewMarkDown}>
                        PREVIEW
                    </button>
                    <div className="flex justify-between gap-x-4 items-center">
                        {/* <Tooltip title="Click to Save.">
                            <button onClick={() => setShowModal(true)}>
                                <SVGIconsList.Save />
                            </button>
                        </Tooltip> */}
                        <div className="flex flex-row items-center gap-x-3">
                            <Tooltip title="Publish as PDF" placement="bottom">
                                <span className="cursor-pointer" onClick={() => setShowModal(true)} >
                                    <SVGIconsList.PublishDocument className="fill-white" />
                                </span>
                            </Tooltip>
                            <Tooltip title="Download as PDF" placement="bottom">
                                <span className="cursor-pointer" onClick={() => downloadToPDF(message?.markdown_text, 'pdf', selectedThread?.title)}>
                                    <SVGIconsList.Download className="fill-white" />
                                </span>
                            </Tooltip>
                        </div>
                        {/* <Dropdown
                            menu={{
                                items: downloadOptions,
                                selectable: true,
                                onClick: handleMenuClick,
                            }}
                            overlayClassName="dropdown-overlay-container"
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <SVGIconsList.Download className="fill-white" />
                            </a>
                        </Dropdown> */}
                    </div>
                </div>
            </div>
            <Modal title="Publish Document" className="custom-popup-modal" open={showModal} onCancel={() => setShowModal(false)} footer="">
                {isDocumentPublishing ?
                    <div className="loader sticky"></div> : <></>
                }
                <Form rootClassName="custom-form" onFinish={handlePublishDocument} className="pt-4" layout="vertical" disabled={isDocumentPublishing}>
                    <Form.Item
                        label="File Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your file name!',
                            },
                        ]}
                        initialValue={selectedThread.title}
                    >
                        <Input className="!bg-darkGray !text-white" />
                    </Form.Item>
                    <Form.Item
                        label="Visibility"
                        name="access_level"
                        rules={[
                            {
                                required: true,
                                message: 'Select Visibility',
                            },
                        ]}
                        initialValue={"private"}
                    >
                        <Radio.Group>
                            <Radio.Button className="!bg-darkGray !text-white/70 !border-lightGrey  [&.ant-radio-button-wrapper-checked]:!border-brand-purple [&.ant-radio-button-wrapper-checked]:!text-white" value="private">Private</Radio.Button>
                            <Radio.Button className="!bg-darkGray !text-white/70 !border-lightGrey  [&.ant-radio-button-wrapper-checked]:!border-brand-purple [&.ant-radio-button-wrapper-checked]:before:!bg-brand-purple [&.ant-radio-button-wrapper-checked]:!text-white" value="public">Public</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="File Indexing"
                        name="index"
                        initialValue={true}
                        className="hidden"
                    >
                        <Radio.Group
                            disabled={true}
                        >
                            <Radio.Button className="!bg-darkGray !text-white/70 !border-lightGrey  [&.ant-radio-button-wrapper-checked]:!border-brand-purple [&.ant-radio-button-wrapper-checked]:!text-white" value={true}>On</Radio.Button>
                            <Radio.Button className="!bg-darkGray !text-white/70 !border-lightGrey  [&.ant-radio-button-wrapper-checked]:!border-brand-purple [&.ant-radio-button-wrapper-checked]:before:!bg-brand-purple [&.ant-radio-button-wrapper-checked]:!text-white" value={false}>Off</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item className="text-end">
                        <Button className="outline-none px-6 [transition:background_0.3s_ease-in-out] h-fit self-end text-lg rounded-full border-[1px] !text-white enabled:hover:!text-white enabled:hover:!bg-brand-gradient enabled:hover:!border-transparent !bg-darkGray" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Message
