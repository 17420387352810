import axios from "axios"
import { ThreadType } from "../../types/research/threadTypes"
import { API_ENDPOINTS } from "./../../utils/apiURL"

export const createThread = async (data: any) => {
    let config = {
        url: API_ENDPOINTS.createThread,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}
export const fetchThreadList = async () => {
    let payload = { username: localStorage.getItem("username") }
    let config = {
        method: "POST",
        url: API_ENDPOINTS.getThreads,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    }

    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}

export const updateThread = async ({ threadId, data }: { threadId: string; data: ThreadType }) => {
    let config = {
        method: "PUT",
        url: `${API_ENDPOINTS.individualThread}${threadId}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
    }
    const response = await axios(config)
        .then((response) => response.data)
        .catch((err) => { })
}

export const deleteThread = async (threadId: string) => {
    let config = {
        method: "DELETE",
        url: `${API_ENDPOINTS.individualThread}${threadId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config).then((response) => response.data)
}

export const fetchThreadDetails = async (threadId: string) => {
    let config = {
        method: "GET",
        url: `${API_ENDPOINTS.individualThread}${threadId}`,
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}