import React, { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { SVGIconsList } from "../../utils/SVGIcons"
import DropdownComponent from "../../utils/dropdownComponent"
import { handleLogout } from "../../auth/keycloakUtils"
import { cn } from "../../utils/clsx"
import { Tooltip } from "antd"
import ThemeContext from '../../utils/ThemeContext';

const LeftSidebar = () => {
    const { pathname } = useLocation()
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    const profileDropdownOptions = [
        {
            label: (
                <div className="flex flex-row items-center gap-2">
                    <SVGIconsList.Logout className="text-white" />
                    <span className="font-light">Logout</span>
                </div>
            ),
            value: "logout",
        },
    ]

    const handleProfileDropdownChange = (value: any) => {
        if (value === "logout") {
            handleLogout()
        }
    }

    const tools = JSON.parse(localStorage.getItem('configTools')!)

    return (
        <aside className="border border-brand-zuno w-[5rem] rounded-full h-full bg-brand-zuno">
            <nav className="m-2 h-full">
                <div className="h-full flex flex-col items-center justify-between">
                    <div className="h-3/6 flex flex-col justify-between gap-y-8 py-6">
                        <div className="w-20 h-20 flex justify-center items-center">
                            {<img src={tools?.ui_config?.logo_url || "/zuno-logo-white.png"} alt="Brand_logo" />}
                        </div>
                        {/* Icons Div */}
                        <div className="h-3/6 flex flex-col gap-y-14 items-center">
                            <Tooltip title="Workspace" placement="right">
                                <Link to="/research">
                                    <SVGIconsList.Research className={cn({ "fill-white": pathname.includes("research"), "fill-white/50": !pathname.includes("research") })} />
                                </Link>
                            </Tooltip>
                            <Tooltip title="Documents" placement="right">
                                <Link aria-disabled to="/document">
                                    <SVGIconsList.Document className={cn({ "fill-white": pathname.includes("document"), "fill-white/50": !pathname.includes("document") })} />
                                </Link>
                            </Tooltip>
                            <Tooltip title="Dashboard" placement="right">
                                <Link aria-disabled to="/dashboard">
                                    <SVGIconsList.Dashboard className={cn({ "fill-white": pathname.includes("dashboard"), "fill-white/50": !pathname.includes("dashboard") })} />
                                </Link>
                            </Tooltip>
                            {localStorage.getItem("user-role") === "admin" ? (
                                <Tooltip title="Config" placement="right">
                                    <Link aria-disabled to="/toolsconfig">
                                        <SVGIconsList.Edit className={cn({ "fill-white": pathname.includes("toolsconfig"), "fill-white/50 stroke-transparent": !pathname.includes("toolsconfig") })} />
                                    </Link>
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className=" h-2/6 flex flex-col justify-end items-center">
                        <div className="w-5/6 mx-auto h-4/6 flex flex-col justify-evenly items-center">
                            {/* <div className="mb-6">
                                <span>
                                    <SVGIconsList.Settings className="fill-white/50" />
                                </span>
                            </div> */}
                            <div className="mb-6">
                                <a href="#" onClick={() => toggleTheme()}>
                                    <img src={isDarkMode ? "/light-mode.svg" : "/dark-mode.svg"}/>
                                </a>
                            </div>
                            <DropdownComponent alignment="right" options={profileDropdownOptions} onChange={handleProfileDropdownChange} className="p-0 mb-8" dropdownClassName="max-h-40">
                                <div className="w-14 h-14 border border-white bg-opacity-15 bg-white rounded-full overflow-hidden flex flex-row items-center justify-center">
                                    <span title={localStorage.getItem("username") || ""} className="uppercase text-2xl">
                                        {localStorage.getItem("username")?.slice(0, 2)}
                                    </span>

                                    {/* <img src="/user_avatar.png" className="filter brightness-[4]" height={40} width={40} alt="Brand_logo" /> */}
                                </div>
                            </DropdownComponent>
                        </div>
                        {/* <div className="h-2/6 mt-8 flex flex-col items-center gap-y-1">
                            <span className="text-xs text-nowrap">Powered By</span>
                            <img src={tools?.ui_config?.powered_by_icon_url || "https://github.com/Byragoni/asset_storage/blob/main/edge_logo.png?raw=true"} width={100} height={150} alt="Edge_Logo" />
                        </div> */}
                    </div>
                </div>
            </nav>
        </aside>
    )
}

export default LeftSidebar
