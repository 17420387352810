import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { fetchMessages } from "../../api/research/messagesAPI"
import { fetchThreadDetails, updateThread } from "../../api/research/threadAPI"
import { ResearchContext } from "../../store/ResearchContext"
import { MessageTypes, RequestMessageTypes, messageTypeValues } from "../../types/research/messageTypes"
import LoaderCircle from "../../utils/LoaderCircle"
import { SVGIconsList } from "../../utils/SVGIcons"
import { API_ENDPOINTS } from "../../utils/apiURL"
import { cn } from "../../utils/clsx"
import { IncludeSourcesEnum, ToolsList, ToolsListTypes } from "../../utils/toolsList"
import TextEditor from "../markdownEditor"
import ToolsInputFields from "./ToolsInputFields"
import MessagesLayout from "./messages/MessagesLayout"
import { Button, Drawer, Form } from "antd"
import { backendTools } from "../../utils/backendToolsConfig"

type ThreadDetailsProps = {
    sendMessage: (message: string | object) => void,
    socket: WebSocket,
    conversationLoadingList: string[]
    setConversationLoadingList: (value: string[]) => void
}

const ThreadDetails = ({ sendMessage, socket, conversationLoadingList, setConversationLoadingList }: ThreadDetailsProps) => {
    const { selectedThread, viewMarkdown, fetchMessagesFlag, setSelectedThread, setViewMarkDown, setFetchThreadsFlag, setFetchMessagesFlag } = useContext(ResearchContext)

    const [_, setSearchParams] = useSearchParams()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const selectedThreadId = queryParams.get("thread_id")

    const [conversationFormInstance] = Form.useForm();

    const titleRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>

    //API SIGNALS CONTROLLERS
    const messagesAPIController = new AbortController()

    const [threadTitle, setThreadTitle] = useState<string>("")
    const [threadContext, setThreadContext] = useState({})
    const [showLogs, setShowLogs] = useState<boolean>(false)
    const [areLogsLoading, setAreLogsLoading] = useState<boolean>(false);
    const [socketLogs, setSocketLogs] = useState<string[]>([]);
    const [messages, setMessages] = useState<MessageTypes[]>([]);
    const [chatLogs, setChatLogs] = useState<MessageTypes[]>([]);
    const [loaderFlag, setLoaderFlag] = useState<boolean>(false)
    const [collapseToolsInputForm, setCollapseToolsInputForm] = useState<boolean>(false)


    const getThreadDetails = async () => {
        setSelectedThread({})
        const response = await fetchThreadDetails(selectedThreadId!)
        if (!response) {
            setSearchParams({})
            return
        }
        setThreadTitle(response.title)
        setThreadContext(response.context)
        setSelectedThread(response)
    }

    const handleExitThreads = () => {
        setSearchParams({})
    }

    const handleTitleChange = (value: string) => {
        if (value.length !== 0 && value !== selectedThread.title) {
            saveUserInputs(value, threadContext)
            setThreadTitle(value)
            setSelectedThread({ ...selectedThread, title: value })
            setTimeout(() => {
                setFetchThreadsFlag(true)
            }, 2000)
        }
        titleRef.current.disabled = true
    }

    const handleSaveContext = (context: any) => {
        if (Object.keys(context).length !== 0) {
            saveUserInputs(threadTitle, { ...threadContext, ...context })
            setThreadContext((prev) => ({ ...prev, ...context }))
        }
    }

    const handleGenerateReport = () => {
        setLoaderFlag(false);
        setAreLogsLoading(true);
        setShowLogs(true)
        try {
            // const socket_url = API_ENDPOINTS.webSocketURL
            // const socket = new WebSocket(socket_url!)
            const requestData = {
                title: threadTitle,
                context: {
                    ...threadContext,
                    type: ToolsList.find((i) => i.tool_name === selectedThread.type)?.tool_id,
                    research_title: threadTitle,
                },
                org_id: localStorage.getItem("org-id"),
                username: localStorage.getItem("username") || "",
                report_type: "vodafone_report",
                agent: "Data Agent",
                conversation_id: selectedThread.conversation_id,
            }
            sendMessage(requestData)

            // socket.onmessage = (event) => {
            //     const data: { type: string; output: string } = JSON.parse(event.data)
            //     if (data.type === "logs") {
            //         setSocketLogs((prev) => [data.output, ...prev])
            //     } else if (data.type === "close_logs" || data.type === "path") {
            //         setAreLogsLoading(false)
            //         handleFetchThreadMessages()
            //         getThreadDetails()
            //         if (selectedThread.title.toLocaleLowerCase().includes("untitled")) {
            //             setTimeout(() => {
            //                 setFetchThreadsFlag(true)
            //             }, 2000)
            //         }
            //     }
            // }

            // socket.onopen = (event) => {
            //     socket.send(JSON.stringify(requestData))
            // }
        } catch (error) {
            console.log(error)
        }
    }

    const saveUserInputs = async (value: string, context: any) => {
        let formatedData = { ...selectedThread, context: context, title: value }

        let payload = {
            threadId: selectedThreadId!,
            data: formatedData,
        }
        updateThread(payload)
    }

    const handleFetchThreadMessages = async () => {
        setLoaderFlag(true)
        let data = await fetchMessages(selectedThreadId!, messagesAPIController)
        if (!data) return
        setMessages(data)
        setLoaderFlag(false)
    }

    const cleanUpStates = () => {
        messagesAPIController.abort()
        setViewMarkDown(false)
        setSelectedThread({})
        setShowLogs(false)
        setAreLogsLoading(false);
        setSocketLogs([])
    }

    const onReceiveSocketMessage = () => {
        if (socket) {
            socket.onmessage = (e) => {

                // const respData: { type: string; output: string } = JSON.parse(e?.data)
                const respData = JSON.parse(e?.data)
                const newMessage: MessageTypes = {
                    context: {
                        flow: respData?.flow || false
                    },
                    text: respData?.output,
                    type: respData?.type,
                    message_id: new Date().getTime().toString(),
                    conversation_id: respData?.conversation_id || selectedThreadId!,
                    username: localStorage.getItem("username")!,
                    markdown_text: "",
                    date_time: respData?.date_time || new Date().toISOString(),
                    sender_type: 2,
                }
                if (respData?.type === messageTypeValues.ANSWER && respData?.flow === messageTypeValues.ASSISTANT) {
                    setConversationLoadingList(conversationLoadingList?.filter((e) => e !== selectedThreadId!));
                    setMessages((prev) => [...prev, newMessage])
                } else if (respData?.type === messageTypeValues.LOGS && respData?.flow === messageTypeValues.ASSISTANT) {
                    // setMessages((prev) => [...prev, newMessage])
                    setChatLogs((prev) => [...prev, newMessage])
                } else if (respData?.type === messageTypeValues.LOGS || respData?.type === messageTypeValues?.SHOW_LOGS || respData?.type === messageTypeValues?.LOGS_INFO) {
                    setSocketLogs((prev) => [respData.output, ...prev])
                } else if (respData.type === messageTypeValues.CLOSE_LOGS || respData.type === messageTypeValues.PATH) {
                    setAreLogsLoading(false)
                    handleFetchThreadMessages()
                    getThreadDetails()
                    if (selectedThread?.title?.toLocaleLowerCase()?.includes("untitled")) {
                        setTimeout(() => {
                            setFetchThreadsFlag(true)
                        }, 2000)
                    }
                }
            }
        }
    }

    useEffect(() => {
        getThreadDetails()
        handleFetchThreadMessages()
        onReceiveSocketMessage();
        return () => cleanUpStates()
    }, [selectedThreadId])

    useEffect(() => {
        if (fetchMessagesFlag) {
            handleFetchThreadMessages()
            setFetchMessagesFlag(false)
        }
    }, [fetchMessagesFlag])

    if (!selectedThread || Object.keys(selectedThread).length === 0) {
        return <div className="loader sticky"></div>
    }

    const handleEditTitle = () => {
        titleRef.current.disabled = false
        titleRef.current.focus()
    }

    const handleConversationQuestionSubmit = (values: any) => {
        const { question } = values;
        if (question && question !== "") {
            conversationFormInstance.resetFields();
            const newMessage: RequestMessageTypes = {
                message_id: new Date().getTime().toString(),
                conversation_id: selectedThreadId!,
                username: localStorage.getItem("username")!,
                org_id: localStorage.getItem("org-id")!,
                text: question,
                markdown_text: "",
                date_time: new Date().toISOString(),
                sender_type: 1,
                assistant: true,
                context: {
                    flow: "assistant",
                },
                include_sources: ToolsList.find((i) => i.tool_name === selectedThread.type)?.include_sources || IncludeSourcesEnum.INTERNAL
            }
            sendMessage(newMessage);
            setConversationLoadingList([...conversationLoadingList, selectedThreadId!])
            setChatLogs([])
            setMessages((prev) => [...prev, newMessage])
        }
    }

    const ToolsList: ToolsListTypes[] = JSON.parse(localStorage.getItem("configTools")!) || backendTools;

    return (
        <div className="w-full h-full flex flex-col overflow-hidden dark:bg-background bg-white">
            <div className="w-full min-h-20 border-b-1 border-white dark:bg-brand-darkGray bg-slate-100">
                <div className="w-full h-full px-8 flex justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                        <button className="flex items-center outline-none focus:outline-none" onClick={handleExitThreads}>
                            <SVGIconsList.Chevron className="dark:fill-white fill-black/70" />
                        </button>
                        <div className="group title-container flex flex-row items-center gap-3">
                            <input
                                ref={titleRef}
                                disabled
                                style={{
                                    width: `${threadTitle.length}ch`,
                                }}
                                placeholder="Set your research title here"
                                className={cn(
                                    `h-full mx-8 text-xl font-normal dark:bg-brand-darkGray bg-transparent outline-none focus:outline-none peer focus:border-b-2 overflow-hidden text-ellipsis dark:text-white text-black/70`,
                                    `w-[${threadTitle.length}ch]`
                                )}
                                defaultValue={threadTitle}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleTitleChange(e.target.value)}
                            />
                            <span
                                className={"group-hover:opacity-90 group-hover:visible invisible opacity-0 [transition:all_0.3s] cursor-pointer peer-focus:hidden"}
                                title="Edit Title"
                                onClick={handleEditTitle}
                            >
                                <SVGIconsList.Edit className="text-white fill-slate-600" />
                            </span>
                        </div>
                    </div>
                    <div className="w-fit flex flex-row items-center gap-x-8">
                        <span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground dark:bg-transparent bg-white dark:text-white text-black/70">
                            <span>KNOW ME</span>
                        </span>
                        <span>
                            <SVGIconsList.BellIcon className="dark:fill-white fill-black/70" />
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className="w-full min-h-16  border-b">
                <input
                    placeholder="Set your research title here"
                    className="w-full h-full px-8 text-xl font-normal bg-background outline-none focus:outline-none"
                    defaultValue={threadTitle}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleTitleChange(e.target.value)}
                />
            </div> */}
            {viewMarkdown ? (
                <>
                    <TextEditor />
                </>
            ) : (
                <>
                    {Object.keys(selectedThread.context).length !== 0 ? <div className="border-t dark:border-black/20 border-slate-400 relative">
                        <div className={cn("[transition:all_0.3s]", collapseToolsInputForm ? "h-0 opacity-0 invisible" : "h-auto")}>
                            <ToolsInputFields generateReport={handleGenerateReport} saveContext={handleSaveContext} />
                        </div>
                        <button
                            title={collapseToolsInputForm ? "Show Config" : "Hide Config"}
                            onClick={() => setCollapseToolsInputForm(!collapseToolsInputForm)}
                            className="dark:bg-transparent bg-brand-darkGray [transition:background_0.3s] hover:bg-opacity-25 absolute border border-t-0 rounded-b-3xl -bottom-[34px] right-0 mr-4 px-4 pb-3 mt-3 pt-2 z-10"
                        >
                            <SVGIconsList.Chevron className={cn("fill-white transform", collapseToolsInputForm ? "-rotate-90" : "rotate-90")} />
                        </button>
                    </div> : null}
                    <div className={cn("h-full overflow-hidden")}>
                        {areLogsLoading && <div className="loader sticky"></div>}
                        {loaderFlag ? (
                            <div className="loader sticky"></div>
                        ) : (
                            <div className="w-full py-2 h-full mx-auto relative overflow-hidden">
                                {/* {showLogs ? (
                                    <div className="w-full h-full flex flex-col justify-center items-center gap-y-3">
                                        <div className="w-fit flex justify-start items-start">
                                            <span className="text-lg font-semibold flex justify-between items-center gap-x-3">
                                                TED is getting the job done... <LoaderCircle />
                                            </span>
                                        </div>

                                        <div className="w-full h-1/2 space-y-2 overflow-y-scroll">
                                            {socketLogs.map((i) => (
                                                <div className="h-fit w-full p-2 border border-dotted text-wrap break-words">{i} </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <MessagesLayout messages={messages} />
                                    )} */}
                                <MessagesLayout
                                    messages={messages?.filter((e) => e?.type !== messageTypeValues.LOGS && e?.conversation_id === selectedThreadId)?.sort((a, b) => new Date(a?.date_time)?.valueOf() - new Date(b?.date_time)?.valueOf())}
                                    isConversationMessageLoading={conversationLoadingList?.includes(selectedThreadId!)}
                                />
                                <div onClick={() => setShowLogs(true)} className="show-logs-btn-container group absolute right-0 top-2/4 flex flex-row gap-0 hover:gap-[10px] p-2 cursor-pointer hover:w-[14ch] w-10 overflow-hidden [transition:all_0.3s_ease-in-out] border rounded-[4rem] justify-end items-center mr-2 dark:bg-transparent bg-black/70 dark:hover:bg-brand-gradient hover:bg-black/40 hover:border-transparent -translate-y-1/2">
                                    <span className="text-sm group-hover:visible group-hover:opacity-100 whitespace-nowrap opacity-0 group-hover:block invisible [transition:all_0.3s_ease-in-out] ">Show Logs</span>
                                    <span className="w-5">
                                        <SVGIconsList.CodeIcon className="fill-white !w-5 !h-5 flex-1" />
                                    </span>
                                </div>
                                <Drawer
                                    open={showLogs}
                                    width={"70%"}
                                    className="dark:!bg-background p-0"
                                    closable={false}
                                    closeIcon={<SVGIconsList.CloseIcon className="dark:text-white text-black/70" />}
                                    onClose={() => setShowLogs(false)}
                                    getContainer={false}>
                                    <div className="w-full h-full flex flex-col justify-start items-center gap-y-3">
                                        {socketLogs?.length > 0 ?
                                            <>
                                                {areLogsLoading ?
                                                    <div className="w-fit flex flex-row gap-x-3 justify-start items-center mb-8 ">
                                                        <div className="flex flex-col h-[120px] w-[100px] items-center relative bg-brain_gif bg-cover bg-center dark:mix-blend-screen mix-blend-difference" />
                                                        {/* <img src="/logo.svg" height={50} width={50} alt="Brand_logo" className="ml-[5px] z-10" /> */}
                                                        {/* <SVGIconsList.Mascot height={100} width={100} className="ml-[5px] z-10" />
                                                            <div className="flex flex-col items-center absolute top-[60px]">
                                                                <div className="robot-hover h-[60px] w-[60px] border-[2px] border-[solid] border-[white] rounded-[100%] [transform:rotateX(70deg)_translateX(-50%)] !border-t-[0px] absolute top-0 left-[50%] animate-[down-fade-out-0_0.3s_ease-in-out_infinite]"></div>
                                                                <div className="robot-hover h-[50px] w-[50px] border-[2px] border-[solid] border-[white] rounded-[100%] [transform:rotateX(70deg)_translateX(-50%)] !border-t-[0px] absolute top-8 left-[50%] animate-[down-fade-out-3_0.3s_ease-in-out_infinite]"></div>
                                                                <div className="robot-hover h-[40px] w-[40px] border-[2px] border-[solid] border-[white] rounded-[100%] [transform:rotateX(70deg)_translateX(-50%)] !border-t-[0px] absolute top-16 left-[50%] animate-[down-fade-out-6_0.3s_ease-in-out_infinite]"></div>
                                                            </div>
                                                            </div> */}
                                                        <span className="text-2xl font-semibold flex justify-between items-center gap-x-3 whitespace-pre-line dark:text-white text-black/70">
                                                            Hi, <br />
                                                            I am new and learning to be fast with every interaction. <br />
                                                            Be with me whilst I generate insights for you.
                                                        </span>
                                                    </div>
                                                    : <></>}
                                                <div className="w-full h-full space-y-2 overflow-y-scroll">
                                                    {socketLogs.map((i) => (
                                                        <div className="h-fit w-full p-2 border border-dotted text-wrap break-words dark:text-white text-black/70">{i} </div>
                                                    ))}
                                                </div>
                                            </>
                                            : <span className="text-lg my-auto dark:text-white text-black/40">No Recent Logs Available</span>
                                        }
                                    </div>
                                </Drawer>

                            </div>
                        )}
                    </div>
                    {ToolsList.find((e) => e?.tool_name === selectedThread.type)?.chat_enabled ?
                        <div className="chat-panel-container w-full px-14 mx-1 pb-4">
                            <div className={cn("loading-message-logs-container dark:text-white/50 text-black/40 pb-1 px-8 font-light text-sm after:animate-[dots_2s_linear_infinite] after:content-['']", conversationLoadingList?.includes(selectedThreadId!) ? "visible" : "invisible h-0")}>{chatLogs?.findLast((e) => e?.type === messageTypeValues.LOGS)?.text || "Loading data"}</div>
                            <Form form={conversationFormInstance} className="conversation-form w-auto flex flex-row items-center gap-x-2 border dark:border-white border-black/70 py-4 px-8 rounded-full" onFinish={handleConversationQuestionSubmit}>
                                <Form.Item
                                    name={"question"}
                                    className="grow m-0"
                                >
                                    <input className="w-full bg-transparent text-lg outline-none dark:text-white text-black/70" placeholder="Ask a question" />
                                </Form.Item>
                                <Button
                                    htmlType="submit"
                                    ghost
                                    className="outline-none border-none"
                                    icon={
                                        <SVGIconsList.Send />
                                    } />
                            </Form>
                        </div> : <></>
                    }
                </>
            )}
        </div>
    )
}

export default ThreadDetails
