import { GlobalConfigProps } from "antd/es/notification/interface"

export const enabled_flows = ["develop_idea", "research_brief", "ideate_future", "retrive_insights", "qna_internal", "qna_external"]

export function parseJwt(token: string) {
    var base64Url = token.split(".")[1]
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join("")
    )

    return JSON.parse(jsonPayload)
}


export const notificationConfig: GlobalConfigProps = {
    placement: "bottomRight",
    duration: 5
}