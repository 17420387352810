import React, { useEffect, useState } from "react"
import AnalyticsList from "./AnalyticsList"
import AnalyticsCharts from "./AnalyticsCharts"
import { API_ENDPOINTS } from "../../utils/apiURL"
import axios from "axios"
import LoaderCircle from "../../utils/LoaderCircle"

const DashBoardPage = () => {
    const [dashboardData, setDashboardData] = useState<any>({});
    const [isDashboardDataLoading, setIsDashboardDataLoading] = useState<boolean>(false)

    useEffect(() => {
        getDashboardData()
    }, [])


    const getDashboardData = () => {
        setIsDashboardDataLoading(true)
        const config = {
            url: `${API_ENDPOINTS.dashboard}`,
            method: "GET",
            params: {
                username: localStorage.getItem("username"),
                org_id: localStorage.getItem("org-id")
            }
        }
        axios(config).then((resp) => {
            setDashboardData(resp?.data?.res);
            setIsDashboardDataLoading(false);
        }).catch((error) => {
            console.error(error);
        })
    }

    if (isDashboardDataLoading) {
        return <div className="h-full w-full flex flex-col items-center justify-center">
            <LoaderCircle />
        </div>
    }


    return (
        <div className="w-full h-full flex flex-row gap-x-2">
            <AnalyticsList dashboardData={dashboardData} />
            <AnalyticsCharts dashboardData={dashboardData} />
        </div>
    )
}

export default DashBoardPage
