import { message } from "antd"
import { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { createThread } from "../../api/research/threadAPI"
import { setToolsData } from "../../api/toolsConfig/toolsConfigAPI"
import { ResearchContext } from "../../store/ResearchContext"
import LoaderCircle from "../../utils/LoaderCircle"
import { SVGIconsList } from "../../utils/SVGIcons"
import { enabled_flows } from "../../utils/constants"
import { ToolsListTypes } from "../../utils/toolsList"
import IdeaSimulationPad from "./IdeaSimulationPad"

type ResearchDocumentCardsTypes = {
    tools: ToolsListTypes[]
}

const ResearchDocumentCards = ({ tools }: ResearchDocumentCardsTypes) => {
    const [_, SetURLSearchParams] = useSearchParams()
    const [loaderFlag, setLoaderFlag] = useState<string>("")
    const [ToolsList, setToolsList] = useState<ToolsListTypes[]>(tools || [])

    const { showIdeaSimulation, setFetchThreadsFlag, setShowIdeaSimulation } = useContext(ResearchContext)

    const [messageApi, contextHolder] = message.useMessage()

    const handleCreateThread = async (researchType: string) => {
        let selectedTool = ToolsList.find((i) => i.tool_name === researchType)
        let toolId = selectedTool?.tool_id
        if (selectedTool?.is_disabled) {
            messageApi.open({
                type: "info",
                content: `This functionality is not yet enabled`,
                duration: 6,
            })
            return
        }
        setLoaderFlag(researchType)
        let emptyContext = {}
        //@ts-ignore
        ToolsList.find((i) => i.tool_name === researchType).items.map((a) => (emptyContext[a.item_id] = ""))
        const response = await createThread({ username: localStorage.getItem("username"), type: researchType, title: "Untitled", context: emptyContext })
        SetURLSearchParams({
            thread_id: response?.conversation_id,
        })
        setLoaderFlag("")
        setFetchThreadsFlag(true)
    }

    // const fetchToolsList = async () => {
    //     const data: ToolsListTypes[] = await setToolsData()
    //     setToolsList(data)
    // }

    // useEffect(() => {
    //     if (!ToolsList || ToolsList.length === 0) {
    //         fetchToolsList()
    //     }
    // }, [])

    useEffect(() => {
        setToolsList(tools)
    }, [tools])


    if (!ToolsList || ToolsList.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center flex-1 h-full">
                <LoaderCircle />
            </div>
        )
    }

    return (
        <div className="h-full flex flex-col">
            {!showIdeaSimulation ? (
                <div className="w-fit mx-auto pt-24 grid grid-cols-3 justify-center items-center gap-6">
                    {/* MAPPING OF INDIVIDUAL TILE GOES HERE */}
                    {contextHolder}
                    {ToolsList.map((tool, index) =>
                        tool.is_disabled ? (
                            <div className="h-[19rem] w-[19rem] rounded-[35px] flex justify-center items-center dark:bg-brand-gradient bg-brand-gradient-gray cursor-pointer" key={tool.tool_name} onClick={tool.tool_id === "idea_simulation" ? () => setShowIdeaSimulation(true) : () => {
                                messageApi.open({
                                    type: "info",
                                    content: `This functionality is not yet enabled`,
                                    duration: 6,
                                })
                            }}>
                                <div className="w-[calc(19rem-2px)] h-[calc(19rem-2px)] m-[0.5px] p-3 rounded-[34px] dark:bg-brand-darkGray">
                                    <div className="w-full h-full rounded-[31px] dark:bg-brand-gradient bg-brand-gradient-gray hover:bg-none rotate-90 overflow-hidden">
                                        <div className="dark:bg-none  w-[calc(100%-2px)] h-[calc(100%-2px)] -rotate-90 m-[1px] rounded-[30px] group dark:bg-brand-darkGray bg-brand-gradient-zuno dark:hover:bg-black/40 hover:bg-brand-gradient hover:bg-cover">
                                            <div className="w-10/12 h-full py-10 mx-auto text-center">
                                                <div className="h-full flex flex-col justify-between items-center">
                                                    <div className="">
                                                        <h1 className="text-3xl font-light text-wrap dark:text-white text-white">{tool.tool_name}</h1>
                                                    </div>
                                                    <div className="w-48 h-11 pl-4 mt-4 dark:bg-white/20 bg-white dark:group-hover:bg-inherit group-hover:bg-white flex justify-around items-center rounded-full border">
                                                        <div>
                                                            <span className="font-normal dark:text-white text-black/70">GET STARTED</span>
                                                        </div>
                                                        <div className="w-8 h-8 mr-[-0.6rem] rounded-full bg-brand-darkGray flex justify-center items-center">
                                                            {loaderFlag === tool.tool_name ? <LoaderCircle /> : <SVGIconsList.Arrow className="fill-white" />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="h-[19rem] w-[19rem] rounded-[35px] flex justify-center items-center dark:bg-brand-gradient bg-brand-gradient-gray cursor-pointer"
                                key={tool.tool_name}
                                onClick={() => handleCreateThread(tool.tool_name)}
                            >
                                <div className="w-[calc(19rem-2px)] h-[calc(19rem-2px)] m-[1px] p-3 rounded-[34px] dark:bg-brand-darkGray">
                                    <div className="w-full h-full rounded-[31px] dark:bg-brand-gradient bg-brand-gradient-gray dark:hover:bg-brand-gradient hover:bg-brand-gradient-light rotate-90 overflow-hidden">
                                        <div className="dark:bg-none w-[calc(100%-2px)] h-[calc(100%-2px)] -rotate-90 m-[1px] rounded-[30px] group dark:bg-brand-darkGray bg-brand-gradient-zuno dark:hover:bg-black/40 hover:bg-brand-gradient hover:bg-cover">
                                            <div className="w-10/12 h-full py-10 mx-auto text-center">
                                                <div className="h-full flex flex-col justify-between items-center">
                                                    <div className="">
                                                        <h1 className="text-3xl font-light text-wrap capitalize dark:text-white text-white">{tool.tool_name}</h1>
                                                    </div>
                                                    <div>
                                                        <p className="text-lg text-wrap font-light leading-5 line-clamp-4 dark:text-white/50 text-white/60">{tool.description}</p>
                                                    </div>
                                                    <div className="w-48 h-11 pl-4 mt-4 dark:bg-white/20 bg-white dark:group-hover:bg-inherit group-hover:bg-white flex justify-around items-center rounded-full border">
                                                        <div>
                                                            <span className="font-normal dark:text-white text-black/70">CREATE NEW</span>
                                                        </div>
                                                        <div className="w-8 h-8 mr-[-0.6rem] rounded-full dark:bg-brand-darkGray bg-brand-zuno flex justify-center items-center">
                                                            {loaderFlag === tool.tool_name ? <LoaderCircle /> : <SVGIconsList.Arrow className="fill-white" />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : (
                <IdeaSimulationPad />
            )}
        </div>
    )
}

export default ResearchDocumentCards
