import axios from "axios"
import { API_ENDPOINTS } from "../../utils/apiURL"
import { handleLogout } from "../../auth/keycloakUtils"
import { notification } from "antd"

// const tempToolsConfig = [
//     {
//       "tool_name": "Research",
//       "tool_id": "research_brief",
//       "report_purpose": "Research information (with sources), Business Opportunities. Use the Persona Framework of a business plan and explain those business ideas with the help of  personas in the target audience domain.",
//       "items": [
//         {
//           "item_id": "company_name",
//           "item_name": "Company",
//           "item_hint": "Type of company acting on the research output. Add company at the end. Ex: Animal Nutrition Company",
//           "item_content_type": "multi_line_text"
//         },
//         {
//           "item_id": "primary_question",
//           "item_name": "Top question",
//           "item_hint": "Main thesis of the report. Ex: Do Dairy farmers see plant based milk alternatives impact their business?",
//           "item_content_type": "multi_line_text"
//         },
//         {
//           "item_id": "research_reason",
//           "item_name": "Reason for research",
//           "item_hint": "Impact on the company. Ex: If nobody buys dairy products, dairy farmers will not buy animal food",
//           "item_content_type": "multi_line_text"
//         },
//         {
//           "item_id": "target_audience",
//           "item_name": "Target audience",
//           "item_hint": "Target audience for possible business opportunities. Eg. Dairy farmers",
//           "item_content_type": "multi_line_text"
//         }
//       ],
//       "image_url": "",
//       "description": "Research on a specific topic to gain more insights around that area",
//       "action_items": "Generate Brief",
//       "is_disabled": false,
//       "report_prompt": "The Document should focus on the answer to the query, should be well structured, informative, in depth and comprehensive, with facts and numbers if available. You should strive to write the report as long as you can using all relevant and necessary information provided. Use an unbiased and journalistic tone. You MUST determine your own concrete and valid opinion based on the given information. Do NOT deter to general and meaningless conclusions. You MUST write all used source urls at the end of the report as references, and make sure to not add duplicated sources, but only one reference for each. Cite search results using inline notations. Only cite the most  relevant results that answer the query accurately. Place these citations at the end of the sentence or paragraph that reference them.Please do your best, this is very important to my career.",
//       "query_prompt": "",
//       "total_words": 1000
//     },
//     {
//       "tool_name": "QnA - Internal",
//       "tool_id": "retrive_insights",
//       "report_purpose": "",
//       "include_sources": "internal",
//       "items": [],
//       "chat_enabled": true,
//       "image_url": "",
//       "description": "Retrieve insights from past conversations or documents",
//       "action_items": "",
//       "is_disabled": false,
//       "report_prompt": "",
//       "query_prompt": "",
//       "no_data_prompt": "Type your message below and hit Send to start the conversation."
//     },
//     {
//       "tool_name": "QnA - External",
//       "tool_id": "retrive_insights",
//       "report_purpose": "",
//       "include_sources": "external",
//       "items": [],
//       "chat_enabled": true,
//       "image_url": "",
//       "description": "Retrieve insights from past conversations or documents",
//       "action_items": "",
//       "is_disabled": false,
//       "report_prompt": "",
//       "query_prompt": "",
//       "no_data_prompt": "Type your message below and hit Send to start the conversation."
//     }
//   ];

export const getToolsConfig = async () => {
    const username = localStorage.getItem("username")
    const org_name = localStorage.getItem("org-id")
    const config = {
        url: `${API_ENDPOINTS.toolConfig}?org_name=${org_name}&username=${username}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.error(err);
            if (err?.response?.status === 401) {
                notification.error({ message: err?.response?.data?.detail || "User is not authorized" })
                setTimeout(() => {
                    handleLogout()
                }, 5000);
            }
        })
}

export const updateToolsConfig = async (data: any) => {
    const username = localStorage.getItem("username")
    const org_name = localStorage.getItem("org-id")
    const payload = {
        data: data,
    }
    const config = {
        url: `${API_ENDPOINTS.toolConfig}?org_name=${org_name}&username=${username}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    }
    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.error(err);
        })
}

export const resetConfig = async () => {
    const username = localStorage.getItem("username")
    const org_name = localStorage.getItem("org-id")
    const config = {
        url: `${API_ENDPOINTS.toolConfig}/reset?org_name=${org_name}&username=${username}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.error(err)
        })
}

export const setToolsData = async () => {
    if (!localStorage.getItem("configTools")) {
        const toolsData = await getToolsConfig()
        localStorage.setItem("configTools", JSON.stringify(toolsData?.tools?.tools))
        return await toolsData?.tools?.tools

        // Below two lines are temporaray and will be removed when the backend implementation is ready
        // console.log(toolsData)
        // localStorage.setItem("configTools", JSON.stringify(tempToolsConfig))
        // return await tempToolsConfig
    } else {
        return JSON.parse(localStorage.getItem("configTools")!)
    }
}