import React, { useContext, useEffect, useState } from "react"
import { SVGIconsList } from "../../utils/SVGIcons"
import { ResearchContext } from "../../store/ResearchContext"
import { cn } from "../../utils/clsx"

const data = [
    {
        title: "I want to explore suitable vendors to help build my idea.",
        vendors: [],
        isDisabled: true,
    },
    {
        title: "I have a vendor identified and now want to understand the onboarding process and workflow.",
        vendors: [],
        isDisabled: true,
    },
    {
        title: "I want to explore existing onboaded vendors to help develop a POC.",
        vendors: [
            {
                title: "Retorio",
                hyperlink: "https://coaching.retorio.com/login",
                description:
                    "An AI-coaching platform that allows you to learn through realistic and repeatable practice in a psychologically safe environment, before being in a ‘high-stakes’ environment such as dealing with a real customer, presenting to leadership etc. It simulates a realistic interaction, records your responses on video and then provides feedback on how  you can improve communication (e.g., use of facial expressions, body language and speech analysis)",
            },
        ],
        isDisabled: false,
    },
]

type CardsType = {
    title?: string
    vendors?: { title: string; hyperlink: string; description: string }[]
    isDisabled?: boolean
}

const IdeaSimulationPad = () => {
    const [selectedCard, setSelectedCard] = useState<CardsType>({})
    const { setShowIdeaSimulation } = useContext(ResearchContext)

    useEffect(() => {
        return () => setShowIdeaSimulation(false)
    }, [])

    return (
        <div className="w-full h-full flex flex-col overflow-hidden bg-brand-darkGray">
            <div className="w-full min-h-20 border-b-1 border-white bg-brand-darkGray">
                <div className="w-full h-full px-8 flex justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                        <button className="flex items-center outline-none focus:outline-none" onClick={() => setShowIdeaSimulation(false)}>
                            <SVGIconsList.Chevron className="fill-white" />
                        </button>
                        <div className="group title-container flex flex-row items-center gap-3">
                            <span className="text-[26px] font-light">Idea Simulation Pad</span>
                        </div>
                    </div>
                    <div className="w-fit flex flex-row items-center gap-x-8">
                        <span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground ">
                            <span>KNOW ME</span>
                        </span>
                        <span>
                            <SVGIconsList.BellIcon className="fill-white" />
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-11/12 grow mx-auto mt-6 mb-8 flex justify-center items-center gap-x-10">
                <div className="w-fit h-full flex flex-col justify-between">
                    {data.map((i) => (
                        <div className="w-[42rem] h-52 rounded-[24px] flex justify-center items-center bg-brand-gradient bg-cover cursor-pointer" key={i.title} onClick={() => setSelectedCard(i)}>
                            <div className="w-[calc(100%-2px)] h-[calc(100%-2px)] m-[1px] p-3 rounded-[22px] bg-brand-darkGray">
                                <div className="w-full h-full rounded-[19px] bg-brand-gradient bg-cover overflow-hidden">
                                    <div
                                        className={cn("w-[calc(100%-2px)] h-[calc(100%-2px)] m-[1px] rounded-[18px] group bg-brand-darkGray hover:bg-transparent", {
                                            "bg-transparent": selectedCard.title === i.title,
                                        })}
                                    >
                                        <div className="w-10/12 h-full py-10 mx-auto text-center">
                                            <div className="w-full h-full flex justify-between items-center">
                                                <div className="w-10/12 text-left">
                                                    <span className="text-3xl font-light leading-[34px]">{i.title} </span>
                                                </div>
                                                <div className="w-10 h-10 flex justify-center items-center border border-1 border-white bg-lightGrey group-hover:bg-transparent rounded-full">
                                                    <div className="w-7 h-7 rounded-full bg-darkGray flex justify-center items-center">
                                                        <SVGIconsList.Arrow className="fill-white rotate-[38deg]" width={10} height={10} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {Object.keys(selectedCard).length !== 0 ? (
                    <div className="w-4/12 h-[45rem] bg-background overflow-hidden">
                        <div className="w-full h-full p-5">
                            {selectedCard.vendors?.length !== 0 ? (
                                <div className="h-full overflow-hidden hover:overflow-y-scroll">
                                    <h4 className="text-white/40">Select Vendors</h4>
                                    <div className="mt-12 divide-y border-b">
                                        {selectedCard.vendors?.map((i) => (
                                            <div className="flex flex-col gap-y-4 py-4">
                                                <h4 className="text-lg">{i.title} </h4>
                                                <p className="text-sm font-light leading-4">{i.description} </p>
                                                <a href={i.hyperlink} target="_blank" className="underline">
                                                    Link to Access
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex justify-center items-center text-white/80">
                                    <span>Coming Soon</span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default IdeaSimulationPad
