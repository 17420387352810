import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { listNodes } from "../../api/document/document"
import { DocumentDataType } from "../../types/document"
import { ToolsList } from "../../utils/toolsList"
import LeftPanelHeader from "../research/LeftPanelHeader"
import TreeView from "./FolderTreeView"
import { SVGIconsList } from "../../utils/SVGIcons"



const DocumentFolderList = () => {
    const [folders, setFolders] = useState<DocumentDataType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getFoldersList();
    }, [])

    const getFoldersList = async (nodeId: string = "root") => {
        setIsLoading(true)
        const queryParams = {
            username: localStorage.getItem("username")
        }
        await listNodes(nodeId, queryParams).then((resp) => {
            if (nodeId === "root") {
                const updatedResponse = resp?.map((elem: DocumentDataType) => ({ ...elem, name: ToolsList.find((tool) => tool?.tool_id === elem?.name)?.tool_name }))
                setFolders(updatedResponse)
            } else {
                setFolders(resp);
            }

        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <div className="flex flex-col w-[27%] h-full overflow-hidden dark:bg-brand-darkGray rounded-2xl">
            <LeftPanelHeader />
            <div className="flex flex-row justify-between items-center pb-3 pt-3 h-10 box-content px-8 dark:text-white text-black/70">
                <span className="text-[22px] font-light">All Folders</span>
                {/* <SVGIconsList.PlusIcon className="fill-white" /> */}
            </div>
            {/* <div className="search-container mx-8 py-4 flex flex-row items-center gap-x-4 border border-white rounded-full px-5 bg-[#303030]">
                <SVGIconsList.SearchIcon className="fill-white" />
                <input className="flex-grow bg-transparent outline-none" placeholder="Search" />
            </div> */}
            {isLoading ? <div className="loader sticky"></div> : <></>}
            <div className="flex-1 w-full overflow-y-auto pb-3 dark:text-white text-black/70">
                <TreeView
                    folders={folders}
                />
            </div>
            {/* <div className="flex mb-2 px-4">
                <button onClick={() => {
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.append("upload", "true");
                    setSearchParams(newSearchParams);
                }} 
                    disabled={searchParams.get("upload") === "true"}
                    className="flex w-full rounded-full bg-brand-gradient-zuno text-white justify-center py-4">
                    <SVGIconsList.Upload className="dark:fill-white fill-black/70" />
                    <span className="pl-2 uppercase">Upload Documents</span>
                </button>
            </div> */}
        </div>
    )
}

export default DocumentFolderList
