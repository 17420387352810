import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import axios from "axios"
import { ThemeProvider } from './utils/ThemeContext';

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
}, function (error) {
    return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>)
