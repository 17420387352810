import { useEffect, useState, useContext } from "react"
import { renderToString } from "react-dom/server"
import Chart, { ChartWrapperOptions } from "react-google-charts"
import LoaderCircle from "../../utils/LoaderCircle"
import { SVGIconsList } from "../../utils/SVGIcons"
import { ToolsListTypes } from "../../utils/toolsList"
import ThemeContext from "../../utils/ThemeContext"

type AnalyticsChartsTypes = {
    dashboardData: {}
}

const AnalyticsCharts = ({ dashboardData }: AnalyticsChartsTypes) => {
    const [docsPieChartData, setDocsPieChartData] = useState<any[]>([]);
    const [threadsPieChartData, setThreadsPieChartData] = useState<any[]>([])
    const [barChartData, setBarChartData] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { isDarkMode } = useContext(ThemeContext);

    useEffect(() => {
        if (Object.keys(dashboardData)?.length > 0) {
            processDashboardData(dashboardData)
        }
    }, [dashboardData])

    function processPieChartData(msgData: any, toolsKey: string) {
        const pieData = Object.entries(msgData)?.map(([key, val]) => {
            const tools: ToolsListTypes[] = JSON.parse(localStorage.getItem("configTools")!)
            const toolKey = tools.find((e: any) => e?.[toolsKey] === key)?.tool_name
            if (toolKey) {
                return [toolKey, val, renderTooltip(`${toolKey}: ${val}`)]
            }
        }).filter((e) => !!e)
        pieData.unshift(["Recipe", "Count", { type: 'string', role: 'tooltip', 'p': { 'html': true } }])
        return pieData
    }

    function processBarChartData(msgData: any, publishedData: any) {
        const barData = Object.entries(msgData)?.map(([msgKey, msgVal]) => {
            const tools: ToolsListTypes[] = JSON.parse(localStorage.getItem("configTools")!)
            const toolKey = tools.find((e) => e?.tool_id === msgKey)?.tool_name
            const publishedVal = publishedData?.[msgKey]
            return [toolKey, msgVal, renderTooltip(`Documents generated for ${toolKey}: ${msgVal}`), publishedVal, renderTooltip(`Documents published for ${toolKey}: ${publishedVal}`)]
        })
        barData.unshift(['Recipe', "Generated Documents", { type: 'string', role: 'tooltip', 'p': { 'html': true } }, "Published Documents", { type: 'string', role: 'tooltip', 'p': { 'html': true } }])
        return barData
    }

    const processDashboardData = (data: any) => {
        const { generated_doc_count, published_doc_count, thread_count } = data
        const docsPieData = processPieChartData(generated_doc_count, 'tool_id')
        const barData = processBarChartData(generated_doc_count, published_doc_count)
        const threadsPieData = processPieChartData(thread_count, 'tool_name')
        setDocsPieChartData(docsPieData);
        setBarChartData(barData);
        setThreadsPieChartData(threadsPieData);
        setTimeout(() => {
            setIsDataLoading(false)
        }, 1000);
    }


    const renderTooltip = (content: any) => {
        return renderToString(<div className="w-full h-full bg-background">
            <div className="w-full h-full p-2">
                <span className="block w-full h-full text-white font-light text-base whitespace-nowrap">{content}</span>
            </div>
        </div>)
    }

    const pieChartOptions: ChartWrapperOptions['options'] = {
        pieHole: 0.5,
        is3D: false,
        backgroundColor: "transparent",
        chartArea: {
            top: 20,
            left: 0,
            right: 0,
        },
        titleTextStyle: {
            color: "white"
        },
        tooltip: {
            isHtml: true,
        },
        pieSliceText: 'none',
        pieSliceBorderColor: "transparent",
        legend: {
            textStyle: {
                color: isDarkMode? "white" : "#333333",
                fontWeight: 300,
                fontSize: 12,
            },
            position: "bottom"
        },
        colors: ["#B32B64", "white", "#7C50B2", "#CA5698", "#34A6C5"]
    };

    const barChartOptions: ChartWrapperOptions['options'] = {
        backgroundColor: "transparent",
        legend: {
            textStyle: {
                color: isDarkMode? "white" : "#333333",
                fontWeight: 300,
                fontSize: 12,
            },
            position: "bottom",
        },
        chartArea: {
            left: 50,
            right: 50,
        },
        isStacked: true,
        tooltip: {
            isHtml: true
        },
        colors: ["#646464", "#C4C4C4"],
        hAxis: {
            gridlines: { count: 0 },
            minorGridlines: { count: 0 },
        },
        vAxis: {
            gridlines: { count: 0 },
            minorGridlines: { count: 0 },
            textStyle: {
                color: "white",
            }
        }
    }

    if (isDataLoading) {
        return <div className="h-full flex-1 flex flex-col items-center justify-center border rounded-2xl ">
            <LoaderCircle />
        </div>
    }
    return (
        <div className="flex-1 flex flex-col h-full dark:bg-brand-darkGray bg-white border rounded-2xl overflow-hidden">
            <div className="w-full min-h-20 border-b-1 border-white dark:bg-brand-darkGray bg-black/20">
                <div className="w-full h-full px-8 flex justify-between items-center">
                    <span className="text-[26px] font-light dark:text-white text-black/70">Dashboard</span>
                    <div className="w-fit flex flex-row items-center gap-x-8">
                        <span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground dark:bg-transparent bg-white dark:text-white text-black/70">
                            <span>KNOW ME</span>
                        </span>
                        <span>
                            <SVGIconsList.BellIcon className="dark:fill-white fill-black/70" />
                        </span>
                    </div>
                </div>
            </div>
            <div className="dashboard-content dark:bg-background bg-white w-full h-[calc(100%-5rem)]">
                <div className="content-header flex flex-row py-6 px-8 justify-between items-center">
                    <span className="text-[22px] font-light dark:text-white text-black/70">Analytics</span>
                </div>
                <div className="dashboard-row flex flex-col justify-between items-center gap-3 h-[calc(100%-6.5rem)] px-5 pb-5 flex-wrap dark:text-white text-black/70">
                    <div className="w-[calc(30%-0.5rem)] h-[calc(50%-0.75rem)] border rounded-lg relative flex flex-col justify-between gap-x-3 p-5 dark:bg-transparent bg-black/5">
                        <span className="w-full">Conversations Created</span>
                        {threadsPieChartData?.length > 1 ?
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="100%"
                                data={threadsPieChartData}
                                options={pieChartOptions}
                            />
                            :
                            <div className="flex flex-col items-center justify-center grow">
                                <span className="font-light text-center dark:text-white text-black/50">No Data Available</span>
                            </div>
                        }
                    </div>
                    <div className="w-[calc(30%-0.5rem)] h-[calc(50%-0.75rem)] border rounded-lg pt-6 px-6 flex flex-col items-center dark:bg-transparent bg-black/5">
                        <span className="w-full">Documents Generated</span>
                        {docsPieChartData?.length > 1 ?
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="100%"
                                data={docsPieChartData}
                                options={pieChartOptions}
                            />
                            :
                            <div className="flex flex-col items-center justify-center grow">
                                <span className="font-light text-center dark:text-white text-black/50">No Data Available</span>
                            </div>
                        }
                    </div>
                    <div className="w-[calc(70%-0.5rem)] h-full border rounded-lg relative pt-6 px-6 pb-3 flex flex-col dark:bg-transparent bg-black/5">
                        <span className="w-full">Documents Generated vs Documents Published</span>
                        {barChartData?.length > 1 ?
                            <Chart
                                chartType="ColumnChart"
                                width={"100%"}
                                height={"100%"}
                                data={barChartData}
                                options={barChartOptions}
                            />
                            :
                            <div className="flex flex-col items-center justify-center grow">
                                <span className="font-light text-center dark:text-white text-black/50">No Data Available</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsCharts
