import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import clsx from 'clsx';
import { SVGIconsList } from './SVGIcons';
import { API_ENDPOINTS } from "../utils/apiURL";

interface FileItem {
    file: File;
    progress: number;
    completed: boolean;
    error: boolean;
    errorMessage: string;
    uploadTask?: AbortController;
}

interface DragDropUploadProps {
    maxFileSize: number;
    chunked: boolean;
    folderId: string;
}

const DragDropUpload: React.FC<DragDropUploadProps> = ({ maxFileSize, chunked, folderId }) => {
    const [files, setFiles] = useState<FileItem[]>([]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            progress: 0,
            completed: false,
            error: false,
            errorMessage: '',
        }));
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        maxSize: maxFileSize,
        multiple: true,
    });

    const uploadFile = async (fileItem: FileItem) => {
        const username = localStorage.getItem("username") || '';
        const formData = new FormData();
        formData.append('file', fileItem.file);
        formData.append('username', username);
        formData.append('parent_id', folderId);

        const uploadTask = new AbortController();
        fileItem.uploadTask = uploadTask;

        try {
            const response = await axios.post(API_ENDPOINTS.documentUpload, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: uploadTask.signal,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFiles(prevFiles => prevFiles.map(f => f.file === fileItem.file ? { ...f, progress } : f));
                },
            });

            if (response.status === 200) {
                setFiles(prevFiles => prevFiles.map(f => f.file === fileItem.file ? { ...f, progress: 100, completed: true } : f));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                setFiles(prevFiles => prevFiles.map(f => f.file === fileItem.file ? { ...f, error: true, errorMessage: 'Upload cancelled' } : f));
            } else {
                setFiles(prevFiles => prevFiles.map(f => f.file === fileItem.file ? { ...f, error: true, errorMessage: 'Upload failed' } : f));
            }
        }
    };

    const handleUpload = (fileItem: FileItem) => {
        uploadFile(fileItem);
    };

    const handleCancel = (fileItem: FileItem) => {
        fileItem.uploadTask?.abort();
    };

    useEffect(() => {
        files.forEach(file => {
            if (!file.completed && !file.error && file.progress === 0) {
                uploadFile(file);
            }
        });
    }, [files]);

    return (
        <div className="w-full h-full flex flex-col p-5 border-2 border-dashed dark:border-white/10 border-slate-300 text-center font-light">
            <div {...getRootProps({ className: clsx('flex-1 flex items-center justify-center p-10 border-2 border-dashed dark:border-white/20 border-slate-400 cursor-pointer dark:text-white/60 text-black/70', { 'bg-green-100': isDragActive }) })}>
                <input {...getInputProps()} />
                {isDragActive ? 
                    <div className="text-xl">Drop files here</div> : 
                    <div className='px-4'>
                        <div className="text-xl py-2">Drag & Drop files or Click to upload</div>
                        <div className="text-sm dark:text-white/40 text-black/50">Only files with the following extensions are allowed: .pdf, .docx, .doc, .xls, .xlsx, .csv, .txt.</div>
                    </div>
                }
            </div>
            <div className="mt-5 mx-h-200px overflow-auto">
                {files.map((fileItem, index) => (
                    <div key={index} className="my-4">
                        <div className="flex justify-between items-end">
                            <div className="flex-1 mr-4">
                                <div className="text-left">{fileItem.file.name}</div>
                                <div className="w-full dark:bg-white/20 bg-black/30 rounded-full h-4 mt-1">
                                    <div className={`h-4 rounded-full ${fileItem.error ? 'bg-red-600' : 'bg-green-600'}`} style={{ width: `${fileItem.progress}%` }}>
                                        <div className="text-white text-center px-2 text-xs">{fileItem.error ? fileItem.errorMessage : `${fileItem.progress}%`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                {!fileItem.completed && (
                                    <button className="px-4 bg-red-500 text-white rounded" onClick={() => handleCancel(fileItem)}>Cancel</button>
                                )}
                                {/* {!fileItem.completed && fileItem.progress > 0 && (
                                    <button className="px-4 bg-blue-500 text-white rounded" onClick={() => handleUpload(fileItem)}>Resume</button>
                                )} */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DragDropUpload;