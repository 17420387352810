import { useEffect, useState } from "react";
import { SVGIconsList } from "../../utils/SVGIcons";

import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { deleteNode, listNodes, readNode } from "../../api/document/document";
import { DocumentDataType } from "../../types/document";
import CheckboxComponent from "../../utils/CheckboxComponent";
import LoaderCircle from "../../utils/LoaderCircle";
import TableComponent, { Column } from "../../utils/TableComponent";
import { Dropdown, MenuProps, Modal, notification } from "antd";

const documentIndexStatus = [
	"Pending",
	"Indexing",
	"Indexed",
	"Error"
];

const statusColor = [
	"#E0A432",
	"#00bfff",
	"#17c317",
	"#ff4500"
];


const FileListComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [tableData, setTableData] = useState<DocumentDataType[]>([]);
	const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false)


	const renderFileIcon = (fileType: string) => {
		switch (fileType) {
			case "document":
				return <SVGIconsList.DocxIcon className="fill-blue-500 w-8 h-7 pr-3" />
			case "xlsx":
				return <SVGIconsList.XlsxIcon className="fill-green-500 w-8 h-7 pr-3" />
			case "pdf":
				return <SVGIconsList.PdfIcon className="fill-red-500 w-8 h-7 pr-3" />
			case "folder":
				return <SVGIconsList.ClosedFolderIcon className="w-8 h-7 pr-3 fill-white" />
			default:
				return <SVGIconsList.Document className="fill-white/65 w-8 h-7 pr-3" />

		}
	}

	const fileActionsList = [
		{
			key: "download",
			label: <div className="flex flex-row items-center gap-x-3 py-1">
				<SVGIconsList.Download className="fill-blue-400 w-5 h-5" />
				<span className="font-light text-base">Download</span>
			</div>
		},
		{
			key: "delete",
			label: <div className="flex flex-row items-center gap-x-3 py-1">
				<SVGIconsList.Trash className="stroke-red-500 w-5 h-5" />
				<span className="font-light text-base">Delete</span>
			</div>
		}
	]

	const deleteFileConfirmation = async (docItem: DocumentDataType) => {
		Modal.warning({
			title: <span className="text-white text-lg font-light">Are you sure you want to delete <span className="font-bold">{docItem?.name}</span>?</span>,
			className: "custom-popup-modal",
			onOk() {
				deleteNode(docItem?._id).then((resp) => {
					if (String(resp?.message)?.toLowerCase() === "success") {
						notification.success({ message: "File Deleted Successfully" })
						getNodeById(searchParams.get("folder_id")!);
					}
				}).catch((e) => {
					console.error(e);
					notification.error({ message: "Something went wrong" });
				})
			},
			okCancel: true,
			okText: "Yes",
			okButtonProps: { className: "outline-none px-6 [transition:background_0.3s_ease-in-out] h-fit self-end text-lg rounded-full border-[1px] !text-white enabled:hover:!text-white enabled:hover:!bg-brand-gradient enabled:hover:!border-transparent !bg-darkGray" },
			cancelButtonProps: { className: "outline-none px-6 [transition:background_0.3s_ease-in-out] h-fit self-end text-lg rounded-full border-[1px] border-none !text-white enabled:hover:!text-white enabled:hover:!bg-brand-gradient enabled:hover:!border-transparent !bg-darkGray" },
		});
	}

	const handleActionsMenuItemClick = async (menuProps: any, docItem: DocumentDataType) => {
		switch (menuProps?.key) {
			case "download":
				notification.info({ message: "Please wait, your file is being downloaded." })
				await readNode(docItem._id).then((response) => {
					if (response) {
						const fileURL = URL.createObjectURL(response)
						const anchor = document.createElement("a");
						anchor.href = fileURL;
						anchor.download = docItem?.name || "download.pdf";
						document.body.appendChild(anchor);
						anchor.click();
						anchor.remove();
						URL.revokeObjectURL(fileURL);
					}
				}).catch((e) => {
					console.error(e);
					notification.error({ message: "File not found" || "Something went wrong" })
				})
				break;
			case "delete":
				deleteFileConfirmation(docItem);
				break;
			default:
				break;
		}
	}

	const tableColumns: Column[] = [
		{
			title: "Title",
			headerClassName: "w-3/12 h-8 text-left font-light text-white/50 pl-12 ml-[50px] mr-10",
			cellClassName: "font-light",
			dataKey: "name",
			render: (title: string, row: any) => (
				<div className="w-full ml-4 flex flex-row gap-x-4 text-left items-center">
					<CheckboxComponent />
					<div className="w-full flex items-center">
						{renderFileIcon(title.split(".").pop() ?? row?.doc_type)}
						<span title={title} className="w-[15rem] text-lg font-light truncate">{title}</span>
					</div>
				</div>
			),
		},
		{
			title: "Date",
			headerClassName: "w-2/12 h-8 text-left font-light text-white/50",
			cellClassName: "font-light",
			dataKey: "updated_time",
			render: (date: string) => <span>{format(new Date(date), "dd/MM/yyyy")} </span>,
		},
		{
			title: "File Size",
			headerClassName: "w-2/12 h-8 text-left font-light text-white/50",
			cellClassName: "font-light",
			dataKey: "fileSize",
			render: (file_size: string) => !file_size ? "-" : file_size
		},
		{
			title: "Status",
			headerClassName: "w-2/12 h-8 text-left font-light text-white/50",
			cellClassName: "font-light",
			dataKey: "indexingStatus",
			render: (status: string) => <span style={{color: statusColor[status || 0]}}>{!status ? documentIndexStatus[0] : documentIndexStatus[status]}</span>
		},
		{
			title: "Added by",
			headerClassName: "w-3/12 h-8 text-left font-light text-white/50",
			cellClassName: "font-light",
			dataKey: "username",
			render: (addedBy: string, row: any) => (
				<div className="flex items-center gap-x-2">
					<div className="w-8 h-8 border dark:border-white border-black/50 bg-opacity-15 dark:bg-transparent bg-black/10 rounded-full overflow-hidden flex flex-row items-center justify-center">
						<span title={addedBy || ""} className="uppercase text-sm">
							{addedBy?.slice(0, 2)}
						</span>
					</div>
					<span className="font-light truncate">{addedBy}</span>
				</div>
			),
		},
		{
			title: "Action",
			headerClassName: "w-1/12 h-8 text-left font-light text-white/50",
			cellClassName: "font-light",
			dataKey: "action",
			render: (text, record) => <Dropdown
				menu={{
					items: fileActionsList,
					onClick: (menuProps) => handleActionsMenuItemClick(menuProps, record)
				}}
				overlayClassName="dropdown-overlay-container"
			>
				<a className="cursor-pointer" onClick={(e) => e?.preventDefault()}>
					<SVGIconsList.HorizontalDots className="dark:fill-white fill-black/50 rotate-90" />
				</a>
			</Dropdown>

		}]


	useEffect(() => {
		if (searchParams.get("folder_id")) {
			getNodeById(searchParams.get("folder_id")!);
		}
	}, [searchParams.get("folder_id")])

	const getNodeById = async (folderId: string) => {
		setIsTableDataLoading(true)
		const queryParams = {
			username: localStorage.getItem("username")
		}
		await listNodes(folderId, queryParams).then((resp) => {
			setTableData(resp)
		}).finally(() => {
			setIsTableDataLoading(false);
		})
	}

	return (
		<div className="flex-1 flex flex-col h-full dark:bg-brand-darkGray bg-white rounded-2xl overflow-hidden">
			<div className="w-full min-h-20 border-b-1 border-white dark:bg-brand-darkGray bg-slate-100">
				<div className="w-full h-full px-8 flex justify-between items-center">
					<span className="text-[26px] font-light dark:text-white text-black/70">Document Management</span>
					<div className="w-fit flex flex-row items-center gap-x-8">
						<span className="w-48 h-14 flex justify-center items-center text-lg rounded-full border border-foreground dark:bg-transparent bg-white dark:text-white text-black/70">
							<span>KNOW ME</span>
						</span>
						<span>
							<SVGIconsList.BellIcon className="dark:fill-white fill-black/70" />
						</span>
					</div>
				</div>
			</div>
			<div className="document-files-content dark:bg-background bg-white w-full h-[calc(100%-5rem)]">
				<div className="content-header flex flex-row py-6 px-8 justify-between items-center">
					<span className="text-[22px] font-light dark:text-white text-black/70">Your Documents</span>
					<div className="flex flex-row gap-x-6 items-center">
						{searchParams.get("folder_id") &&
						<button onClick={() => {
							const newSearchParams = new URLSearchParams(searchParams);
							newSearchParams.append("upload", "true");
							setSearchParams(newSearchParams);
						}} 
							disabled={searchParams.get("upload") === "true"}
							// className="flex w-full rounded-full bg-brand-gradient-zuno text-white justify-center py-2 px-4"
						>
							<SVGIconsList.Upload className="dark:fill-white fill-black/70" />
							{/* <span className="pl-2 uppercase">Upload</span> */}
						</button>}
						{/* <SVGIconsList.SearchIcon className="fill-white" />
						|
						<SVGIconsList.LeftAlignedMenu className="fill-white" /> */}
					</div>
				</div>
				<div className="dashboard-row flex flex-row items-center gap-3 h-[calc(100%-6.5rem)] px-5 pb-5 flex-wrap">
					{isTableDataLoading ?
						<div className="flex flex-col items-center justify-center flex-1 h-full">
							<LoaderCircle />
						</div> :
						searchParams.get("folder_id") ?
							<TableComponent dataSource={tableData} columns={tableColumns} /> :
							<div className="h-full w-full flex flex-col items-center justify-center"><span className="text-center text-xl font-light dark:text-white text-black/50">Select a folder to view it's contents</span></div>
					}
				</div>
			</div>
		</div>
	);
};

export default FileListComponent;
