import { notification } from "antd"
import { useEffect, useContext } from "react"
import { BrowserRouter } from "react-router-dom"
import RoutesComponent from "./Routes"
import { notificationConfig } from "./utils/constants"
import ThemeContext from './utils/ThemeContext';

function App() {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    useEffect(() => {
        notification.config(notificationConfig);
        if (window.navigator.platform !== "MacIntel") {
            window.addEventListener("resize", resizeApplication)
            resizeApplication()
        }
        return () => {
            if (window.navigator.platform !== "MacIntel") {
                window.removeEventListener("resize", resizeApplication)
            }
        }
    }, [])

    const resizeApplication = () => {
        const dpr = window.devicePixelRatio
        const zoomOutLevel = 1 / dpr
            ; (document.body.style as any).zoom = zoomOutLevel
    }

    return (
        <div className={`${isDarkMode ? 'dark' : 'light'} h-full w-full`}>
            <div className={`dark:bg-background p-3 h-full w-full bg-slate-200`}>
                <BrowserRouter>
                    <RoutesComponent />
                </BrowserRouter>
            </div>
        </div>
    )
}

export default App
