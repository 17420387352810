import React, { useState } from "react";

type CheckboxProps = {
  onClick?: (value: boolean) => void;
};

const CheckboxComponent = ({ onClick }: CheckboxProps) => {
  const [checked, setChecked] = useState(false);
  const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (onClick) {
      onClick(isChecked);
    }
  };

  return (
    <div className="relative bg-transparent h-[24px] w-[24px] border rounded">
      <input
        type="checkbox"
        checked={checked}
        onChange={onToggle}
        className={`absolute ${
          checked ? "opacity-100" : "opacity-0"
        } h-full w-full outline-none`}
      />
    </div>
  );
};

export default CheckboxComponent;
