// let BASE_URL = "https://ted.cognida.ai"
// let BASE_URL = "http://dx-backend:5000"
// let BASE_URL = "http://40.116.66.11:5000"
let BASE_URL = process.env.REACT_APP_API_URL

// export const userName = localStorage.getItem('username') || "Raymond";

const { protocol } = window.location

// export const API_ENDPOINTS = {
//     baseUrl: BASE_URL,
//     getThreads: `${BASE_URL}/conversations`,
//     createThread: `${BASE_URL}/conversation`,
//     individualThread: `${BASE_URL}/conversation/`,
//     getMessages: `${BASE_URL}/messages/`
// }

export const API_ENDPOINTS = {
    baseUrl: BASE_URL,
    // webSocketURL: `${protocol === "https:" ? "wss" : "ws"}://localhost:5000/ws`,
    webSocketURL: `${protocol === "https:" ? "wss" : "ws"}://${process.env.REACT_APP_WS_URL || "localhost:5000/ws/"}`,
    getThreads: `${BASE_URL}/conversations`,
    createThread: `${BASE_URL}/conversation`,
    individualThread: `${BASE_URL}/conversation/`,
    getMessages: `${BASE_URL}/messages/`,
    nodes: `${BASE_URL}/nodes`,
    toolConfig: `${BASE_URL}/config`,
    dashboard: `${BASE_URL}/dashboard`,
    documentUpload: `${BASE_URL}/nodes/upload-file`
}
