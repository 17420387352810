import { Suspense, useContext } from "react"
import { ResearchContext } from "../../store/ResearchContext"
import LoaderCircle from "../../utils/LoaderCircle"
import { ToolsListTypes } from "../../utils/toolsList"

type PropsTypes = {
    generateReport: () => void
    saveContext: (context: any) => void
}

const ToolsInputFields = ({ generateReport, saveContext }: PropsTypes) => {
    const { selectedThread } = useContext(ResearchContext)
    const ToolsList: ToolsListTypes[] = JSON.parse(localStorage.getItem("configTools")!)

    const selectedToolItems = ToolsList.find((i) => i.tool_name === selectedThread.type)

    if (!selectedToolItems || Object.keys(selectedThread || {}).length === 0) {
        return (
            <div className="w-full h-[50rem] flex justify-center items-center">
                <LoaderCircle />
            </div>
        )
    }

    return (
        <Suspense fallback={<LoaderCircle />}>
            <div className="px-8 py-4 h-fit mx-auto flex justify-between items-end space-x-4 border-b dark:border-white/20 border-slate-400 dark:bg-black/20 bg-slate-100">
                <div className="w-full flex justify-between space-x-6">
                    <form
                        className="w-full grid grid-cols-3 items-center gap-5 dark:text-white text-black/70"
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault()
                            generateReport()
                        }}
                    >
                        {selectedToolItems!.items.map((i, index) => (
                            <div className="w-full flex flex-col justify-between items-start gap-y-2" key={i.item_name}>
                                <label htmlFor={i.item_id} className="font-light capitalize text-wrap break-words">
                                    {i.item_name}
                                </label>
                                {i?.item_content_type === "dropdown" ?
                                <div className="w-4/5 h-12 flex justify-between items-center rounded-md border">
                                    <div className="w-full">
                                        <select
                                            defaultValue={selectedThread?.context?.[i.item_id]}
                                            onChange={(e) => saveContext({ [i.item_id]: e.target.value })}
                                            className="block py-2.5 px-4 w-full text-xl font-light cursor-pointer dark:text-white text-black/70 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                        >
                                            <option value="">{i.item_hint}</option>
                                            {i?.options?.map((op) => (
                                                <option className="dark:bg-brand-darkGray dark:text-white bg-white text-black/70" value={op} key={op}>
                                                    {op}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div> :
                                <textarea
                                    id={i.item_id}
                                    className="w-full h-16 outline-none bg-transparent border p-2 placeholder:text-base overflow-hidden hover:overflow-y-scroll"
                                    placeholder={i.item_hint}
                                    //@ts-ignore
                                    defaultValue={selectedThread?.context?.[i.item_id]}
                                    //@ts-ignore
                                    onBlur={(e) => saveContext({ [i.item_id]: e.target.value })}
                                    required
                                />
                                }
                            </div>
                        ))}

                        {selectedToolItems?.tool_id !== "chat_with_db" &&
                        <button className="w-fit h-14 self-end text-lg rounded-full border-[1px] dark:border-white border-black/70 dark:hover:bg-brand-gradient hover:bg-black/10" type="submit">
                            <span className="px-6 uppercase">{selectedToolItems?.action_items}</span>
                        </button> }
                    </form>
                </div>
            </div>
        </Suspense>
    )
}

export default ToolsInputFields
