import { Suspense, useContext, useEffect, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { updateMessage } from "../../api/research/messagesAPI"
import { ResearchContext } from "../../store/ResearchContext"
import LoaderCircle from "../../utils/LoaderCircle"
var showdown = require("showdown")
let converter = new showdown.Converter()

export default function TextEditor() {
    const { selectedMessage, setViewMarkDown, setFetchMessagesFlag } = useContext(ResearchContext)
    const [content, setContent] = useState("")
    const [loaderFlag, setLoaderFlag] = useState(false)

    const quillModules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            // [{ 'align': [] }],

            ["clean"], // remove formatting button
        ],
    }

    const quillFormats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "link", "image", "align", "color", "code-block"]

    const handleEditorChange = (newContent: any) => {
        setContent(newContent)
    }
    const handleSaveDocument = async () => {
        setLoaderFlag(true)
        let markDowncontent = content
        await updateMessage({
            message_id: selectedMessage.message_id,
            text: markDowncontent,
        })
        setLoaderFlag(false)

        // closeViewDocument(false)
    }

    const handleCloseMarkdown = () => {
        setViewMarkDown(false)
        setFetchMessagesFlag(true)
    }

    useEffect(() => {
        let convertedValue = converter.makeHtml(selectedMessage.markdown_text)
        setContent(convertedValue)
    }, [])

    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full px-10 h-16 flex justify-between items-center border-b">
                <span className="text-lg font-light">Edit your Research Brief</span>
                <div className="flex items-center my-3 mr-14 gap-x-6">
                    <button
                        className="outline-none focus:outline-none w-32 h-10 flex justify-evenly items-center rounded-full border-[1px] border-white hover:bg-brand-gradient"
                        onClick={handleCloseMarkdown}
                    >
                        <span className="text-sm font-semibold">CLOSE</span>
                    </button>
                    <button
                        title="Click to Save your changes"
                        onClick={handleSaveDocument}
                        className="w-32 h-10 flex justify-evenly items-center rounded-full border-[1px] border-white hover:bg-brand-gradient"
                    >
                        <span className="text-sm font-semibold flex justify-between items-center gap-x-3">SAVE {loaderFlag && <LoaderCircle />} </span>
                        {/* <Save className="w-5 h-5" /> */}
                    </button>
                </div>
            </div>
            <div className="w-full overflow-scroll flex flex-col bg-white text-black text-xl">
                <Suspense fallback={null}>
                    <div className="h-full w-[95%] mx-auto react-quill-container">
                        <ReactQuill value={content} onChange={handleEditorChange} modules={quillModules} formats={quillFormats} className="w-full h-[70%] mt-10 text-lg" />
                    </div>
                </Suspense>
            </div>
        </div>
    )
}
