export type MessageTypes = {
    message_id: string
    conversation_id: string
    username: string
    text: string
    markdown_text: string
    date_time: string
    sender_type: number
    context: any
    type?: string
}

export type RequestMessageTypes = MessageTypes & {
    assistant: boolean,
    org_id: string,
    include_sources: string
}

export const messageTypeValues = {
    ANSWER: "answer",
    CLOSE_LOGS: "close_logs",
    PATH: "path",
    LOGS: "logs",
    ASSISTANT: "assistant",
    SHOW_LOGS: "show_logs",
    LOGS_INFO: "logs_info"
}