import { Navigate, Route, Routes } from "react-router-dom"
import MainLayout from "./components/main"
import ResearchPage from "./components/research"
import DocumentLayout from "./components/document"
import DashBoardPage from "./components/dashboard"
import LoginComponent from "./auth/loginComponent"
import ToolsConfigPage from "./components/toolsConfig"

const RoutesComponent = () => {
    return (
        <Routes>
            <Route path="/signin" element={<LoginComponent />} />
            <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to="/research" />} />
                <Route path="/research" element={<ResearchPage />} />
                <Route path="/document" element={<DocumentLayout />} />
                <Route path="/dashboard" element={<DashBoardPage />} />
                <Route path="/toolsconfig" element={<ToolsConfigPage />} />
            </Route>
        </Routes>
    )
}

export default RoutesComponent
