import React, { createContext, useContext, useEffect, useState } from "react"
import { SVGIconsList } from "../../utils/SVGIcons"
import { DocumentDataType } from "../../types/document"
import { cn } from "../../utils/clsx"
import { useSearchParams } from "react-router-dom"

interface FolderProps {
    folder: DocumentDataType
    first?: boolean
}

interface TreeViewProps {
    folders: DocumentDataType[]
}

type ContextTypes = {
    activeFolder: string | number
    setActiveFolder: (folder: string | number) => void
}
const FolderContext = createContext<ContextTypes>({
    activeFolder: "",
    setActiveFolder: (folder: string | number) => { },
})

const FolderComponent: React.FC<FolderProps> = ({ folder, first }) => {
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const [isOpen, setIsOpen] = useState(URLSearchParams.get("folder_id") === folder?._id)
    const { activeFolder, setActiveFolder } = useContext(FolderContext)

    const toggleFolder = (folderId: string) => {
        setActiveFolder(!isOpen ? folderId : "")
        setIsOpen(!isOpen)
        SetURLSearchParams(!isOpen ? { folder_id: folderId } : {})
    }

    useEffect(() => {
        if (activeFolder !== folder?._id) {
            setIsOpen(false)
        }

        if (!URLSearchParams.get("folder_id")) {
            setActiveFolder("")
        }
    }, [activeFolder, URLSearchParams])

    const filteredChildrenFolders = folder?.children?.filter((e) => e?.doc_type !== "document") || []
    return (
        <div key={folder?._id} className={`folder-container ${first ? "border-l-[transparent]" : "border-l-[darkGray]"}  border-l-[2px]`}>
            <div
                className="folder-title flex flex-row justify-between items-center cursor-pointer px-3 py-1.5"
                onClick={() => folder?.doc_type !== "document" ? toggleFolder(folder?._id) : () => { }}
            >
                <div className={`flex flex-row items-center gap-x-3`}>
                    {!!filteredChildrenFolders && filteredChildrenFolders?.length === 0 ? (
                        <>
                            {/* <div className="bg-darkGray w-[11px] h-[11px] my-2 mr-2"></div> */}
                            {isOpen ? <SVGIconsList.OpenFolderIcon className={cn(first ? "" : "text-[#ffffff66]")} /> : <SVGIconsList.ClosedFolderIcon className={cn(first ? "dark:fill-white fill-black/70" : "text-[#ffffff66] dark:fill-white fill-black/70")} />}
                            <span className={`text-base ${isOpen ? "font-normal" : "font-light"}`}>{folder?.name}</span>
                        </>
                    ) : (
                        <>
                            {isOpen ? (
                                <>
                                    {/* <SVGIconsList.Chevron className='fill-[#d5d5d5] transform duration-300 -rotate-90' /> */}
                                    <SVGIconsList.OpenFolderIcon />
                                </>
                            ) : (
                                <>
                                    {/* <SVGIconsList.Chevron className='fill-[#d5d5d5] transform duration-300 rotate-90' /> */}
                                    <SVGIconsList.ClosedFolderIcon className="dark:fill-white fill-black/70"/>
                                </>
                            )}
                            <span
                                className={cn("text-base font-light", {
                                    "font-normal": isOpen,
                                })}
                            >
                                {folder?.name}
                            </span>
                        </>
                    )}
                </div>
                {"children" in folder && Array.isArray(filteredChildrenFolders) && filteredChildrenFolders?.length > 0 ? (
                    <div className="dark:bg-background bg-white/80 flex flex-row items-center justify-center h-6 w-6 text-xs font-light rounded-sm">{filteredChildrenFolders?.length}</div>
                ) : (
                    <></>
                )}
            </div>
            <div className={`children-container ml-[1.4rem] ${first ? "border-l-[transparent]" : "border-l-[darkGray]"} transition-opacity duration-300 ${isOpen ? "opacity-100" : "opacity-0"}`}>
                {isOpen ? (
                    filteredChildrenFolders
                        ?.sort(
                            (a, b) =>
                                //@ts-ignore
                                b?.children?.length - a?.children?.length
                        )
                        ?.map((child) => <FolderComponent first={false} key={child?._id} folder={child} />)
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}

const TreeView: React.FC<TreeViewProps> = ({ folders }) => {
    const [activeFolder, setActiveFolder] = useState<string | number>("")
    return (
        <FolderContext.Provider
            value={{
                activeFolder,
                setActiveFolder,
            }}
        >
            <div className="tree-view px-5 pb-8 pt-5">
                {folders
                    //@ts-ignore
                    ?.sort((a, b) => b?.children?.length - a?.children?.length)
                    ?.map((folder) => (
                        <React.Fragment key={folder?._id}>
                            <FolderComponent first={true} folder={folder} />
                            <div className="bg-lightGrey h-[1px] m-4"></div>
                        </React.Fragment>
                    ))}
            </div>
        </FolderContext.Provider>
    )
}

export default TreeView
