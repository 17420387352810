import { SVGIconsList } from "../../utils/SVGIcons"
import React, { useEffect, useState } from 'react'
import DropdownComponent from "../../utils/dropdownComponent";
import LeftPanelHeader from "../research/LeftPanelHeader";
import LoaderCircle from "../../utils/LoaderCircle";
type AnalyticsListTypes = {
    dashboardData: any
}

type AnalyticsDataTypes = {
    conversations_created: string | number;
    documents_published: string | number;
    documents_generated: string | number;
    users: string | number;
}

const AnalyticsList = ({ dashboardData }: AnalyticsListTypes) => {
    const [selectedYear, setSelectedYear] = useState('2024');
    const [analyticsData, setAnalyticsData] = useState<AnalyticsDataTypes | {}>({});
    const [isDataLoading, setIsDataLoading] = useState(true);

    useEffect(() => {
        if (Object.keys(dashboardData)?.length > 0) {
            processAnalyticsData(dashboardData)
        }
    }, [dashboardData])

    const processAnalyticsData = (data: any) => {
        const { generated_doc_count, published_doc_count, thread_count, user_count } = data
        const genDocCount = Object.values(generated_doc_count)?.reduce((acc, cur) => Number(acc) + Number(cur), 0);
        const threadCount = Object.values(thread_count)?.reduce((acc, cur) => Number(acc) + Number(cur), 0);
        const publishedCount = Object.values(published_doc_count)?.reduce((acc, cur) => Number(acc) + Number(cur), 0);
        setAnalyticsData({
            "number_of_users": user_count as number,
            "conversations_created": threadCount as number,
            "documents_published": publishedCount as number,
            "documents_generated": genDocCount as number,
        })
        setIsDataLoading(false);
    }

    const analyticsOptions = [
        {
            label: "2024",
            value: "2024",
        },
    ];


    const analytics = Object.entries(analyticsData)?.map(([key, val], i) => (
        <div key={i} className="dark:bg-background bg-white/40 h-[140px] px-8 py-6 flex flex-row rounded-lg">
            <div className="flex flex-col justify-end h-full">
                <SVGIconsList.Analytics className={"dark:fill-lightGrey fill-black/70 align-bottom"} />
            </div>
            <div className="flex flex-col justify-between pl-9 grow dark:text-white text-black/70">
                <span className="text-lg font-light capitalize">{key.replaceAll("_", " ")}</span>
                <span className="text-[56px] font-semibold">{val}</span>
            </div>
            {/* <div className="bg-[#f5f5f6] bg-opacity-10 h-fit rounded-2xl flex flex-row gap-x-1 items-center px-2 justify-self-end">
                <SVGIconsList.Arrow className={"fill-successGreen transform rotate-[-53deg]"} />
                <span className="text-base text-successGreen font-normal">{(Math.random() * 100).toFixed(2)}%</span>
            </div> */}
        </div>
    ))

    if (isDataLoading) {
        <div className="w-[27%] h-full overflow-hidden bg-brand-darkGray border rounded-2xl flex flex-col justify-center items-center">
            <LoaderCircle />
        </div>
    }

    return (
        <div className="w-[27%] h-full overflow-hidden dark:bg-brand-darkGray bg-white/60 border rounded-2xl">
            <LeftPanelHeader />
            <div className="flex flex-row justify-between items-center pb-3 pt-4 h-14 box-content px-8">
                <span className="text-[22px] font-light dark:text-white text-black/70">Organizational Analytics</span>
                <div className="rounded overflow-hidden">
                    <DropdownComponent options={analyticsOptions} alignment="bottom" onChange={setSelectedYear} triggerClassName={"dark:bg-brand-darkGray bg-white dark:text-white text-black/70"}>
                        <div className="flex flex-row gap-4 px-2 items-center">
                            <span>{selectedYear !== "" ? selectedYear : "Select Year"}</span>
                            <SVGIconsList.Chevron className="dark:fill-white fill-black/70 transform -rotate-90" />
                        </div>
                    </DropdownComponent >
                </div>
            </div>
            <div className="analytics-list flex flex-col gap-y-5 pb-3 px-8 h-[calc(100%-10.75rem)] overflow-y-auto">{analytics}</div>
        </div>
    )
}

export default AnalyticsList
