import { useSearchParams } from "react-router-dom";
import { Drawer } from "antd";
import DocumentFolderList from "./DocumentFolderList"
import FileListComponent from "./FileListComponent"
import UploadComponent from "./UploadComponent"
import { SVGIconsList } from "../../utils/SVGIcons"

const DocumentLayout = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="document-layout-container w-full h-full flex flex-row gap-x-2">
            <DocumentFolderList/>
            <FileListComponent key={searchParams.get("upload") === "true" ? new Date().getTime() : 'doc-list'} />

            <Drawer
                open={searchParams.get("upload") === "true" && !!searchParams.get("folder_id")}
                width={"70%"}
                className="dark:!bg-background p-0"
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                closeIcon={<SVGIconsList.CloseIcon className="dark:text-white text-black/70" />}
                onClose={() => {
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.delete("upload");
                    setSearchParams(newSearchParams);
                }}
                getContainer={false}>
                <div className="w-full h-full flex flex-col justify-start items-center gap-y-3">
                    <UploadComponent/>
                </div>
            </Drawer>
        </div>
    )
}

export default DocumentLayout
