import Keycloak, { KeycloakOnLoad } from "keycloak-js";

export const keycloakOnLoadOption: KeycloakOnLoad = 'login-required';

let initOptions = {
    url: process.env?.REACT_APP_AUTH_SERVER_URL || "https://zuno-auth.azurewebsites.net",
    realm: process.env?.REACT_APP_AUTH_REALM || "Cognida",
    clientId: process.env?.REACT_APP_AUTH_CLIENT || "vodafone-prod",
    onLoad: keycloakOnLoadOption, // check-sso | login-required
    // KeycloakResponseType: 'code',
}

export const keycloak = new Keycloak(initOptions);

export const handleLogout = () => {
    localStorage.clear();
    // keycloak.clearToken();
    keycloak.logout();
}