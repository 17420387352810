import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoaderCircle from '../utils/LoaderCircle';
import { handleLogout, keycloak, keycloakOnLoadOption } from './keycloakUtils';
import { parseJwt } from '../utils/constants';
import { setToolsData } from '../api/toolsConfig/toolsConfigAPI';

const LoginComponent = () => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const setUserProfile = async () => {
        const userProfile = await keycloak.loadUserProfile();
        localStorage.setItem("token", keycloak.token || "");
        localStorage.setItem("username", userProfile?.username || "");
        localStorage.setItem("email", userProfile?.email || "");
        const parsedInfo = parseJwt(keycloak?.token || "")
        localStorage.setItem("user-role", parsedInfo?.['user-role'] || "")
        localStorage.setItem("org-id", parsedInfo?.['org-id'] || "zuno-dx"); //ealier it was vodafone
        await setToolsData();
        return userProfile;
    }

    const authenticateUser = async () => {
        try {
            if (keycloak.loginRequired === undefined) {
                const authInit = await keycloak.init({
                    onLoad: keycloakOnLoadOption,
                });
            }

            const tokenExpired = await keycloak.isTokenExpired();
            if (!keycloak.authenticated || tokenExpired) {
                const auth = await keycloak?.login().then(async (resp) => {
                    console.log(resp);
                });
                await setUserProfile().then(() => {
                    setIsLoading(false);
                    navigate("/research")
                });
            } else if (!localStorage.getItem("token") || !localStorage.getItem('username')) {
                await setUserProfile().then(() => {
                    setIsLoading(false);
                    navigate("/research")
                });
            } else {
                setIsLoading(false)
                navigate("/research")
            }

        } catch (error) {
            keycloak.clearToken();
            setIsLoading(false);
            handleLogout();
        }
    }

    useEffect(() => {
        authenticateUser();
    }, [])

    if (isLoading) {
        return <div className="h-full w-full flex flex-col items-center justify-center">
            <LoaderCircle />
        </div>
    }

    return (
        <>Login Page</>
    )
}

export default LoginComponent;