export type ToolsListTypes = {
    tool_name: string
    tool_id: string
    items: {
        item_id?: string
        item_hint?: string
        item_name: string
        item_content_type: string
    }[]
    image_url?: string
    description: string
    action_items: string
    is_disabled: boolean
    chat_enabled?: boolean
    no_data_prompt?: string
    include_sources?: string
}

export const IncludeSourcesEnum = {
    INTERNAL: "internal",
    EXTERNAL: "external"
}

export const ToolsList: ToolsListTypes[] = [
    {
        tool_name: "Develop an idea",
        tool_id: "develop_idea",
        items: [
            {
                item_name: "Background Context",
                item_content_type: "multi_line_text",
                item_id: "background_context",
                item_hint: "Provide background info for your idea, problem statement, the need for it etc..",
            },
            {
                item_name: "Target Audience",
                item_content_type: "multi_line_text",
                item_id: "target_audience",
                item_hint: "Whose problem are you trying to solve?",
            },
            {
                item_name: "Top Questions ",
                item_content_type: "multi_line_text",
                item_id: "top_questions",
                item_hint: "what is it we don’t know and want answers about",
            },
            {
                item_name: "Impact",
                item_content_type: "multi_line_text",
                item_id: "impact",
                item_hint: "What tangible/intangible value (benefit) you believe this idea can bring to the organisation",
            },
        ],
        image_url: "",
        description: "Generate your Innovation Document with a clear Problem statement, Purpose and Benefits",
        action_items: "Generate Idea",
        is_disabled: false,
    },
    {
        tool_name: "Research",
        tool_id: "research_brief",
        items: [
            {
                item_id: "company_name",
                item_name: "Company",
                item_hint: "Type of company acting on the research output. Add company at the end. Ex: Animal Nutrition Company",
                item_content_type: "multi_line_text",
            },
            {
                item_id: "primary_question",
                item_name: "Top question",
                item_hint: "Main thesis of the report. Ex: Do Dairy farmers see plant based milk alternatives impact their business?",
                item_content_type: "multi_line_text",
            },
            {
                item_id: "research_reason",
                item_name: "Reason for research",
                item_hint: "Impact on the company. Ex: If nobody buys dairy products, dairy farmers will not buy animal food",
                item_content_type: "multi_line_text",
            },
            {
                item_id: "target_audience",
                item_name: "Target audience",
                item_hint: "Target audience for possible business opportunities. Eg. Dairy farmers",
                item_content_type: "multi_line_text",
            },
        ],
        image_url: "",
        description: "Create your idea thought leadership or execution plan with credible research insights and diverse personas",
        action_items: "Generate Brief",
        is_disabled: false,
    },
    {
        tool_name: "Ideate Future",
        tool_id: "ideate_future",
        items: [
            {
                "item_id": "product_service",
                "item_name": "Product",
                "item_hint": "What is the main product/service your company provides? Eg. Milk production.",
                "item_content_type": "multi_line_text",
            },
            {
                "item_id": "industry",
                "item_name": "Industry",
                "item_hint": "What industry is your company active in? Eg. Dairy",
                "item_content_type": "multi_line_text",
            },
            {
                "item_id": "future_year",
                "item_name": "Future Year",
                "item_hint": "For what future year do you want to create a future scenario? Eg. 2047",
                "item_content_type": "multi_line_text",
            },
        ],
        image_url: "",
        description: "World Never Stops, Neither do we! Let’s Imagine and Build future",
        action_items: "Ideate",
        is_disabled: false,
    },
    {
        tool_name: "Retrieve Insights",
        tool_id: "retrive_insights",
        items: [],
        image_url: "",
        description: "Gain insights of conversations happening with TED",
        action_items: "",
        is_disabled: false,
    },
    {
        tool_name: "Business case generation",
        tool_id: "business_case_generation",
        items: [],
        image_url: "",
        description: "Generate Business Case for your idea in seconds!",
        action_items: "",
        is_disabled: false,
    },
    {
        tool_name: "Idea Simulation Pad",
        tool_id: "idea_simulation",
        items: [],
        image_url: "",
        description: "Comming Soon!",
        action_items: "",
        is_disabled: true,
    },
]
