import axios, { ResponseType } from "axios"
import { API_ENDPOINTS } from "../../utils/apiURL"

type CreateNodePayload = {
    name: string
    parent_name: string
    org_name: string
    doc_type: string
    content?: string
    thread_id?: string
    access_level?: string
    index?: boolean,
    username: string,
}

/**
 * @description Creates a new node, which can be either a document or a folder. The path is derived from the parent's path.
 * @param payload Attributes to create (e.g., name, type, content for documents)
 */
export const createNode = async (payload: CreateNodePayload) => {
    let config = {
        url: API_ENDPOINTS.nodes,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    }
    return axios(config)
}

/**
 * @description Retrives details about the node by its Id, including all properties.
 * @param nodeId Id of the Node to be updated
 */
export const readNode = async (nodeId: string) => {
    let config = {
        url: `${API_ENDPOINTS.nodes}/${nodeId}`,
        method: "GET",
        responseType: "blob" as ResponseType,
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config)
        .then((response) => response.data)
    // .catch((err) => console.log(err))
}

/**
 *@description Updates specific properties of a node. If it's a document, the content can be updated. The path may need to be recalculated if the name changes.
 * @param nodeId Id of the Node to be updated
 * @param payload Attributes to update (e.g., name, content for documents)
 */
export const updateNode = async (nodeId: string, payload: CreateNodePayload) => {
    let config = {
        url: `${API_ENDPOINTS.nodes}/${nodeId}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}

/**
 * @description Deletes the node and, if it's a folder, optionally all its content recursively.
 * @param nodeId Id of the Node to be deleted
 *
 */
export const deleteNode = async (nodeId: string) => {
    let config = {
        url: `${API_ENDPOINTS.nodes}/${nodeId}`,
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            username: localStorage.getItem("username")
        }
    }
    return await axios(config)
        .then((response) => response.data)
    // .catch((err) => console.log(err))
}

/**
 * @description Moves the node (document or folder) to a different folder. This updates the parent_id and recalculates the path.
 * @param nodeId
 * @param parent_id
 */
export const moveNode = async (nodeId: string, parent_id: string) => {
    let config = {
        url: `${API_ENDPOINTS.nodes}/${nodeId}`,
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}
/**
 * @description Lists all child nodes (both folders and documents) of a specified folder node.
 * @param nodeId Id of the node for which the child nodes to be returned.
 */
export const listNodes = async (nodeId: string, query: any) => {
    let config = {
        url: `${API_ENDPOINTS.nodes}/${nodeId}/children`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        params: query
    }
    return await axios(config)
        .then((response) => response.data)
        .catch((err) => console.log(err))
}
