import { createContext, useState } from "react"
import { MessageTypes } from "../types/research/messageTypes"
import { ThreadType } from "../types/research/threadTypes"

type ResearchContextTypes = {
    selectedThread: ThreadType
    viewMarkdown: boolean
    selectedMessage: MessageTypes
    fetchThreadsFlag: boolean
    fetchMessagesFlag: boolean
    showIdeaSimulation:boolean
    setFetchThreadsFlag: (val: boolean) => void
    setSelectedThread: (val: any) => void
    setViewMarkDown: (val: boolean) => void
    setSelectedMessage: (val: MessageTypes) => void
    setFetchMessagesFlag: (val: boolean) => void
    setShowIdeaSimulation:(val:boolean) => void
}

let defaultThreadValues: ThreadType = {
    context: {
        company_name: "",
        primary_question: "",
        research_reason: "",
        target_audience: "",
    },
    conversation_id: "",
    created_time: "",
    is_deleted: false,
    title: "",
    type: "",
    updated_time: "",
    username: "",
}

let defaultMessageValue = { message_id: "", conversation_id: "", username: "", text: "", markdown_text: "", date_time: "", sender_type: 2, context: {} }

export const ResearchContext = createContext<ResearchContextTypes>({
    selectedThread: defaultThreadValues,
    viewMarkdown: false,
    selectedMessage: defaultMessageValue,
    fetchThreadsFlag: false,
    fetchMessagesFlag: false,
    showIdeaSimulation:false,
    setSelectedThread: (val) => {},
    setViewMarkDown: (val: boolean) => {},
    setSelectedMessage: (val: MessageTypes) => {},
    setFetchThreadsFlag: (val: boolean) => {},
    setFetchMessagesFlag: (val: boolean) => {},
    setShowIdeaSimulation:(val:boolean) => {}
})

type Props = {
    children: React.ReactNode
}

export const ResearchContextProvider = ({ children }: Props) => {
    const [selectedThread, setSelectedThread] = useState<any>({})
    const [viewMarkdown, setViewMarkDown] = useState<boolean>(false)
    const [selectedMessage, setSelectedMessage] = useState<MessageTypes>(defaultMessageValue)
    const [fetchThreadsFlag, setFetchThreadsFlag] = useState<boolean>(false)
    const [fetchMessagesFlag, setFetchMessagesFlag] = useState<boolean>(false)
    const [showIdeaSimulation, setShowIdeaSimulation] = useState<boolean>(false)

    return (
        <ResearchContext.Provider
            value={{
                selectedThread,
                viewMarkdown,
                selectedMessage,
                fetchThreadsFlag,
                fetchMessagesFlag,
                showIdeaSimulation,
                setSelectedThread,
                setViewMarkDown,
                setSelectedMessage,
                setFetchThreadsFlag,
                setFetchMessagesFlag,
                setShowIdeaSimulation
            }}
        >
            {children}
        </ResearchContext.Provider>
    )
}
