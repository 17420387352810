import { format, isEqual, isSameWeek, isToday, isYesterday } from "date-fns";
import { MessageTypes } from "../../../types/research/messageTypes";
import { cn } from "../../../utils/clsx";
import Message from "./Message";
import { useContext, useEffect, useRef } from "react";
import { Drawer } from "antd";
import { ResearchContext } from "../../../store/ResearchContext";
import { ToolsListTypes } from "../../../utils/toolsList";

type MessagesLayoutTypes = {
  messages: MessageTypes[];
  isConversationMessageLoading: boolean;
};

const MessagesLayout = ({ messages, isConversationMessageLoading }: MessagesLayoutTypes) => {
  const messageContainerRef = useRef(null);

  const { selectedThread } = useContext(ResearchContext)

  const toolsList: ToolsListTypes[] = JSON.parse(localStorage.getItem("configTools")!) || [];

  const formatDate = (dateTime: string): string => {
    let tempDateString = new Date(dateTime);
    if (isToday(tempDateString)) return "Today";
    else if (isYesterday(tempDateString)) return "Yesterday";
    else return format(tempDateString, "dd/MM/yyyy");
  };

  useEffect(() => {
    var objDiv = messageContainerRef?.current as unknown as HTMLElement
    if (!!objDiv) objDiv.scrollTop = objDiv.scrollHeight
  }, [messages])

  return (
    <div ref={messageContainerRef} className="h-full px-12 overflow-y-auto" id="messages_container">
      <div
        className={cn("w-full flex border-gray-200 flex-1 flex-col gap-3 p-3", {
          "h-full": messages.length === 0,
        })}
      >
        {messages?.length > 0 ? (
          messages
            // .sort(
            //   (a, b) =>
            //     new Date(b.date_time).valueOf() -
            //     new Date(a.date_time).valueOf()
            // )
            .map((message, index) => {
              let dateString = formatDate(message.date_time);
              if (index !== 0) {
                let prevMessageDate = messages[index - 1].date_time;
                if (
                  isEqual(
                    new Date(prevMessageDate).getDay(),
                    new Date(message.date_time).getDay()
                  )
                ) {
                  dateString = "";
                }
              }
              return (
                <div className="text-center" key={message.message_id}>
                  {dateString && (
                    <div className="flex justify-center">
                      <div className="p-[1px] branding_gradient rounded-full">
                        <div className="px-4 py-1 rounded-full bg-brand-darkGray">
                          <span className="text-sm text-white/80">
                            {dateString}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">
                    <Message key={message.message_id} message={message} />
                  </div>
                </div>
              );
            })
        ) : (
          <div className="w-full h-full flex justify-center items-center text-center">
            <span className="text-2xl text-gray-400">
              {toolsList?.find((e: ToolsListTypes) => e?.tool_name === selectedThread?.type)?.no_data_prompt ||
                "Generate your report by entering the required fields on the top."
              }
            </span>
          </div>
        )}
        <div className={cn("loading-message-container flex items-end flex-row", isConversationMessageLoading ? "visible" : "hidden")}>
          <div
            className={cn("w-14 h-14 border border-white bg-opacity-15 bg-white rounded-full overflow-hidden flex flex-row items-center justify-center", "order-1 border border-[#BBBBBB] pl-1 rounded-br-none")}
          >
            <img src="/robot.svg" alt="User_Icon" width={40} height={40} />
          </div>
          <div
            className={cn("max-w-xl px-4 min-h-12 mx-2 flex flex-row items-center rounded-md bg-brand-darkGray overflow-hidden order-2 gap-x-1.5 justify-center")}
          >
            <div className='h-3 w-3 bg-white/80 rounded-full animate-bounce [animation-duration:0.8s] [animation-delay:-0.3s]'></div>
            <div className='h-3 w-3 bg-white/80 rounded-full animate-bounce [animation-duration:0.8s] [animation-delay:-0.15s]'></div>
            <div className='h-3 w-3 bg-white/80 rounded-full animate-bounce [animation-duration:0.8s]'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesLayout;
