import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ResearchContextProvider } from "../../store/ResearchContext"
import { SVGIconsList } from "../../utils/SVGIcons"
import { cn } from "../../utils/clsx"
import ResearchDocumentCards from "./ResearchDocumentCards"
import ThreadDetails from "./ThreadDetails"
import ThreadListContainer from "./ThreadListContainer"
import { setToolsData } from "../../api/toolsConfig/toolsConfigAPI"
import { API_ENDPOINTS } from "../../utils/apiURL"
import { notification } from "antd"
import { ToolsListTypes } from "../../utils/toolsList"

const ResearchPage = () => {
    const location = useLocation();

    const socketRef = useRef<WebSocket>();

    const queryParams = new URLSearchParams(location.search)
    const selectedThread = queryParams.get("thread_id")

    const [viewThreads, setViewThreads] = useState<boolean>(true);
    const [toolsList, setToolsList] = useState<ToolsListTypes[]>([]);
    const [conversationLoadingList, setConversationLoadingList] = useState<string[]>([]);


    useEffect(() => {
        getToolsData();
        initSocketConnection();
        return () => {
            disconnectSocket();
        }
    }, []);

    const getToolsData = async () => {
        const tools = await setToolsData();
        setToolsList(tools);
    }

    const initSocketConnection = () => {
        const socket = connectToSocket();

        if (socket) {
            socket.onopen = (event: Event) => {
                console.log("connected to Socket", event)
            }
        } else {
            notification.error({ message: "Couldn't connect to chat. Please refresh the Page" })
        }
    }

    const connectToSocket = (): WebSocket | null => {
        try {
            const socketUrl = API_ENDPOINTS.webSocketURL;
            // const socketUrl = "ws://localhost:5000/ws";
            const socket = new WebSocket(socketUrl);
            socket.onclose = (ev) => {
                console.log("socket Closed", ev)
            }
            socket.onerror = (ev) => {
                console.error("Socket Error", ev)
            }
            socketRef.current = socket;
            return socket;
        } catch (error) {
            console.error(error)
            return null;
        }
    }

    const disconnectSocket = () => {
        try {
            if (socketRef.current) {
                socketRef.current?.close();
            }
        } catch (error) {
            console.error(error);
        }
    }


    const getSocketObject = () => {
        return socketRef.current;
    };

    const sendMessage = (message: string | object) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(JSON.stringify(message));
        } else {
            notification.info({ message: "Chat disconnected." })
        }
    };


    return (
        <ResearchContextProvider>
            <div className="w-full relative h-full flex flex-row gap-x-2 ">
                {viewThreads ? <ThreadListContainer tools={toolsList} /> : null}
                <button
                    className={cn("w-12 h-12 dark:bg-background bg-brand-zuno left-[26%] p-2 absolute top-1/2 rounded-full flex justify-center items-center border transition-all z-60", {
                        "left-0 rotate-180": !viewThreads,
                    })}
                    onClick={() => setViewThreads((prev) => !prev)}
                >
                    <SVGIconsList.Arrow className="fill-white -rotate-[145deg]" />
                </button>
                <div className="flex-1 h-full bg-primary dark:border-white/10 border-slate-400 rounded-2xl overflow-hidden">
                    <div className="h-full flex flex-col">
                        {!selectedThread ? (
                            <div className="w-full h-full flex flex-col bg-primary overflow-hidden dark:bg-background bg-white/60">
                                <div className="h-full flex flex-col overflow-y-scroll ">
                                    <ResearchDocumentCards tools={toolsList} />
                                </div>
                            </div>
                        ) : (
                            <ThreadDetails
                                conversationLoadingList={conversationLoadingList}
                                setConversationLoadingList={setConversationLoadingList}
                                sendMessage={sendMessage} socket={socketRef.current!} />
                        )}
                    </div>
                </div>
            </div>
        </ResearchContextProvider>
    )
}

export default ResearchPage
