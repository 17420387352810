import React, { useState, useRef, useEffect } from 'react';
import { cn } from './clsx';

interface OptionType {
    label: string | React.ReactNode;
    value: string | number | boolean;
}

interface DropdownTypes {
    children: React.ReactNode;
    className?: string;
    dropdownClassName?: string;
    triggerClassName?: string;
    alignment: "right" | "bottom" // | "left";
    options: OptionType[],
    onChange?: (value: any) => any
}

const DropdownComponent = ({ children, className, dropdownClassName, alignment, options, onChange, triggerClassName }: DropdownTypes) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const triggerRef = useRef<any>(null);
    const componentRef = useRef<any>(null);
    const dropdownRef = useRef<any>(null);

    const getAlignmentStyles = (type: string) => {
        switch (type) {
            case "trigger":
                switch (alignment) {
                    // case "left":
                    //     return ""
                    case "bottom":
                        return `${isDropdownOpen ? "!rounded-b-none !border-b-transparent" : ""}`;
                    case "right":
                        return "p-0 border-none"
                    default:
                        break;
                }
                break;
            case "dropdown":
                switch (alignment) {
                    // case "left":
                    //     return ""
                    case "bottom":
                        return `border rounded-lg !rounded-t-none w-full overflow-x-hidden ${isDropdownOpen ? "border-t-0" : ""}`;
                    case "right":
                        return `top-0 left-full ml-[15px] before:content-[""] before:h-[25px] before:w-[25px] before:rotate-45 before:bg-[#212121] before:top-3 before:-left-[3px] before:block before:absolute before:z-0 before:[box-shadow:0_0_2px_white] [box-shadow:0_0_2px_white] rounded`
                    default:
                        break;
                }
                break;
        }
    }

    const handleDropdownBlur = (event: any) => {
        if (componentRef?.current && !componentRef.current?.contains(event?.target)) {
            setIsDropdownOpen(false);
        }
    }

    useEffect(() => {
        if (isDropdownOpen) {
            window.addEventListener("mousedown", handleDropdownBlur);
            handleDropdownPositioning();
        } else {
            window.removeEventListener("mousedown", handleDropdownBlur);
        }

        return () => {
            window.removeEventListener("mousedown", handleDropdownBlur);
        }
    }, [isDropdownOpen]);

    const handleDropdownPositioning = () => {
        if (triggerRef?.current && dropdownRef?.current) {
            dropdownRef.current.style.position = "fixed";
            dropdownRef.current.style.width = alignment === 'bottom' ? `${triggerRef?.current?.offsetWidth}px` : 'fit-content';
            const triggerBoundingData = triggerRef?.current?.getBoundingClientRect();
            dropdownRef.current.style.top = alignment === 'bottom' ? `${triggerBoundingData?.bottom}px` : `${triggerBoundingData?.top}px`;
            dropdownRef.current.style.left = alignment === "bottom" ? `${triggerBoundingData?.left}px` : `${triggerBoundingData?.right}px`;
        }
    }

    const handleOptionSelect = (option: OptionType) => {
        if (onChange) {
            onChange(option?.value);
        }
        setIsDropdownOpen(!isDropdownOpen);
    }

    return (
        <div ref={componentRef} className={cn(`dropdown-component-container relative`, className)} onBlur={() => setIsDropdownOpen(false)}>
            <div ref={triggerRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className={cn(`dropdown-trigger cursor-pointer p-3 [transition:border-radius_0.3s,_opacity_0.3s,_visibility_0.3s] border rounded-lg`, getAlignmentStyles("trigger"), triggerClassName)}
            >
                {children}
            </div>
            <div ref={dropdownRef} className={cn(`absolute flex flex-col dark:bg-darkGray bg-white dark:text-white text-black/70  [transition:border-radius_0.3s,_opacity_0.3s,_visibility_0.3s] max-h-96 z-10 ${isDropdownOpen ? "visible opacity-100" : "invisible opacity-0"}`, getAlignmentStyles("dropdown"), dropdownClassName)}  >
                {options?.length > 0 ?
                    <div className='flex flex-col h-full w-full overflow-y-auto rounded-lg'>
                        {options?.map((opt, ind) => {
                            return <div key={ind} className="list-item-container dark:bg-darkGray bg-white z-10 p-3 dark:hover:bg-background hover:bg-black/5 [transition:background-color_0.3s] cursor-pointer" onClick={() => handleOptionSelect(opt)}>
                                <span>{opt?.label}</span>
                            </div>
                        })}
                    </div>
                    :
                    <>No Data Available</>}
            </div>
        </div>
    )
}

export default DropdownComponent;