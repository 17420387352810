import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { handleLogout, keycloak } from "../../auth/keycloakUtils";
import LoaderCircle from "../../utils/LoaderCircle";
import { SVGIconsList } from "../../utils/SVGIcons";
import { cn } from "../../utils/clsx";
import DropdownComponent from "../../utils/dropdownComponent";
import LeftSidebar from "./leftSidebarComponent";

const MainLayout = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const authenticateUser = async () => {
        try {
            if (keycloak.loginRequired === undefined) {
                navigate("/signin");
            }

            const tokenExpired = await keycloak.isTokenExpired();
            if (!keycloak.authenticated || tokenExpired || !localStorage.getItem("token") || !localStorage.getItem('username')) {
                setIsLoading(false);
                navigate("/signin");
            } else {
                setIsLoading(false)
            }

        } catch (error) {
            keycloak.clearToken();
            setIsLoading(false);
            handleLogout();
        }
    }

    useEffect(() => {
        setIsLoading(true);
        authenticateUser();
    }, [pathname])




    if (isLoading) {
        return <div className="h-full w-full flex flex-col items-center justify-center">
            <LoaderCircle />
        </div>
    }

    return (
        <div className="flex h-full gap-x-2 max-w-screen-3xl mx-auto">
            <LeftSidebar />
            <main className="w-[calc(100%-5.5rem)] h-full">
                <Outlet />
            </main>
        </div>
    )
}

export default MainLayout
