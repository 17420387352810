import { Dropdown, message } from "antd"
import { isToday } from "date-fns"
import { format } from "date-fns/format"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { createThread, deleteThread, fetchThreadList } from "../../api/research/threadAPI"
import { ResearchContext } from "../../store/ResearchContext"
import { ThreadType } from "../../types/research/threadTypes"
import LoaderCircle from "../../utils/LoaderCircle"
import { SVGIconsList } from "../../utils/SVGIcons"
import { cn } from "../../utils/clsx"
import { tools, ToolsListTypes } from "../../utils/tools"
import DropdownComponent from "../../utils/dropdownComponent"
import { enabled_flows } from "../../utils/constants"
import LeftPanelHeader from "./LeftPanelHeader"

type ThreadListContainerTypes = {
    tools: ToolsListTypes[]
}


const ThreadListContainer = ({ tools }: ThreadListContainerTypes) => {
    const [_, SetURLSearchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()

    const { fetchThreadsFlag, setFetchThreadsFlag, setShowIdeaSimulation } = useContext(ResearchContext)

    const [threadList, setThreadList] = useState<ThreadType[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectValue, setSelectValue] = useState("")
    const [threadListFilter, setThreadListFilter] = useState<string[]>([])

    const queryParams = new URLSearchParams(location.search)
    const selectedThreadId = queryParams.get("thread_id")
    const [messageApi, contextHolder] = message.useMessage()

    const handleFetchThreads = async () => {
        setIsLoading(true)
        const data: ThreadType[] = await fetchThreadList()
        setThreadList(data)
        setIsLoading(false)
    }

    const handleCreateNewThread = async (researchType: string) => {
        let selectedTool = tools.find((i) => i.tool_name === researchType)
        let toolId = selectedTool?.tool_id
        setSelectValue(researchType)
        if (toolId === "idea_simulation") {
            setShowIdeaSimulation(true)
            return;
        }
        if (selectedTool?.is_disabled) {
            messageApi.open({
                type: "info",
                content: `This functionality is not yet enabled`,
                duration: 6,
            })
            // setSelectValue("")
            return
        }

        let emptyContext = {}
        //@ts-ignore
        tools.find((i) => i.tool_name === researchType).items.map((a) => (emptyContext[a.item_id] = ""))
        const response = await createThread({ username: localStorage.getItem("username"), type: researchType, title: "Untitled", context: emptyContext })
        SetURLSearchParams({
            thread_id: response?.conversation_id,
        })
        handleFetchThreads()
    }

    const handleDeleteThread = async (threadId: string) => {
        await deleteThread(threadId)
        handleFetchThreads()
        if (selectedThreadId === threadId) {
            SetURLSearchParams({})
        }
    }

    const handleSelectThread = (thread: any) => {
        SetURLSearchParams({
            thread_id: thread.conversation_id,
        })
    }

    const items = [
        {
            key: "delete",
            label: (
                <a
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                >
                    <div className="space-x-2 flex flex-row items-center text-lg">
                        <SVGIconsList.Trash className="stroke-red-500" /> <span className="">Delete</span>
                    </div>
                </a>
            ),
        },
        // {
        //     key: "share",
        //     label: (
        //         <div className="space-x-2 flex flex-row">
        //             <SVGIconsList.share className="text-sm stroke-blue-500" /> <span className="text-sm">Share</span>
        //         </div>
        //     ),
        //     disabled: true,
        // },
    ]

    useEffect(() => {
        handleFetchThreads()
    }, [])

    useEffect(() => {
        if (fetchThreadsFlag) {
            handleFetchThreads()
            setFetchThreadsFlag(false)
        }
    }, [fetchThreadsFlag])

    const handleFilterChange = (value: string) => {
        if (value === "reset") {
            setThreadListFilter([])
        } else {
            if (threadListFilter.includes(value)) {
                setThreadListFilter((prev) => prev?.filter((v) => v !== value))
            } else {
                setThreadListFilter((prev) => [...prev, value])
            }
        }
    }

    // const toolsNames = tools.map((i) => i.tool_name)
    const researchDropdownOptions = tools?.map((elem) => ({ label: elem?.tool_name, value: elem?.tool_name, key: elem?.tool_name }))
    const filteredThreadList = threadList?.filter((item) => (threadListFilter?.length > 0 ? threadListFilter?.includes(item?.type) : true))
    return (
        <div className="w-[27%] h-full p-2 dark:bg-brand-darkGray rounded-2xl">
            {contextHolder}
            <div className="h-full flex flex-col">
                {/* This is the header part, this will be static */}
                <div className="h-fit w-full p-4 pb-2 pt-2 space-y-10 top-0 sticky dark:bg-brand-darkGray z-10 bg-white/10">
                    <LeftPanelHeader className="px-2 py-0" />
                    {/* <div className="px-2 py-1">
                        <SVGIconsList.TedLogo className="fill-white" />
                    </div> */}
                    <div className="mx-auto flex flex-row justify-evenly items-center">
                        <DropdownComponent
                            options={researchDropdownOptions?.map((e) => ({
                                label: <span className="pl-10 text-xl font-light">{e.label}</span>,
                                value: e.value,
                            }))}
                            alignment="bottom"
                            triggerClassName="rounded-[24px] w-full dark:bg-brand-darkGray bg-white"
                            dropdownClassName="rounded-[24px]"
                            className="w-full mx-5 ml-2 roun"
                            onChange={(value) => {
                                console.log(`Option selected - ${value}`)
                                setSelectValue(value)
                                handleCreateNewThread(value)
                            }}
                        >
                            <div className="flex flex-row items-center justify-evenly gap-4">
                                <SVGIconsList.PlusIcon className="dark:fill-white ml-2 h-5 w-5 fill-black/70" />
                                <span className="w-full text-xl font-light dark:text-white text-black/70">{selectValue || "Create New"}</span>
                                <SVGIconsList.Chevron className="dark:fill-white transform -rotate-90 mr-2 fill-black/70" />
                            </div>
                        </DropdownComponent>
                        {/* <div className="hidden w-4/5 h-12 p-4 flex justify-between items-center space-x-4 rounded-full border">
                            <span>
                                <SVGIconsList.PlusIcon className="fill-white" />
                            </span>
                            <div className="w-full">
                                <select
                                    value={selectValue}
                                    onChange={(e) => handleCreateNewThread(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-xl font-light cursor-pointer text-white bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                >
                                    <option value="">Start a Research</option>
                                    {tools.map((i) => (
                                        <option className="bg-brand-darkGray text-white" value={i.tool_name} key={i.tool_name}>
                                            {i.tool_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div> */}

                        <div className="1/5">
                            <Dropdown
                                menu={{
                                    items: researchDropdownOptions
                                        .map((e) => ({
                                            ...e,
                                            label: (
                                                <div className="flex flex-row items-center gap-3">
                                                    <SVGIconsList.CheckWithCircle className={cn(threadListFilter?.includes(e?.key) ? "fill-blue-500" : "invisible", "h-5 w-5")} />{" "}
                                                    <span>{e?.label}</span>
                                                </div>
                                            ),
                                        }))
                                        .concat([
                                            {
                                                key: "reset",
                                                label: (
                                                    <div className="flex flex-row items-center gap-3">
                                                        <SVGIconsList.Reset className="stroke-blue-500 w-5 h-5" />
                                                        <span>Reset</span>
                                                    </div>
                                                ),
                                                value: "reset",
                                            },
                                        ]),
                                    // selectable: true,
                                    onClick: (item) => handleFilterChange(item.key),
                                }}
                                overlayClassName="dropdown-overlay-container"
                            >
                                <a className="cursor-pointer" onClick={(e) => e.preventDefault()}>
                                    <SVGIconsList.FiltersIcon className="dark:fill-white fill-black/70" />
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {/* The below div will be the list of the threads  */}
                <div className={cn(threadListFilter?.length > 0 ? "visible" : "invisible", "font-light opacity-85 pb-2 ps-6 text-sm dark:text-white text-black/70")}>{`Filtered By: ${threadListFilter?.join(", ")}.`}</div>
                {isLoading ? (
                    <>
                        <div className="loader sticky"></div>
                        <div className="w-full h-full flex justify-center items-center">
                            <LoaderCircle />
                        </div>
                    </>
                ) : (
                    <div className="w-full h-full mt-0 p-1 overflow-y-scroll">
                        <div className="">
                            {/* Individual Thread List wll be mapped below */}
                            {/* START OF INDIVIDUAL THREAD */}
                            {!!filteredThreadList && filteredThreadList?.length > 0 ? (
                                filteredThreadList
                                    .sort((a, b) => new Date(b.updated_time).valueOf() - new Date(a.updated_time).valueOf())
                                    .map((i, index) => (
                                        <div
                                            className={cn("h-28 px-2 border-b-2 border-black/10 cursor-pointer hover:border-brand-zuno dark:hover:bg-background hover:bg-black/5", {
                                                "dark:bg-background bg-black/5 border-b-2 border-brand-zuno": selectedThreadId === i.conversation_id,
                                            })}
                                            key={i.conversation_id}
                                        >
                                            <div className="w-full h-full flex justify-around gap-x-2 py-4">
                                                {/* <div className="w-1/6 flex justify-center items-baseline" onClick={() => handleSelectThread(i)}>
                                                    <SVGIconsList.CheckWithCircle className="fill-brand-green" />
                                                </div> */}
                                                <div className="w-4/6 h-full flex flex-col items-start justify-between" onClick={() => handleSelectThread(i)}>
                                                    <span className="w-full text-xl font-light leading-6 truncate dark:text-white text-black">{i.title}</span>

                                                    <div className="p-[1px] rounded-full">
                                                        <div className="px-4 py-1 rounded-full dark:bg-black/20 bg-white">
                                                            <span className="text-sm dark:text-white/50 text-black/70 capitalize">{i.type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-1/6 mr-4 flex flex-col justify-end items-end">
                                                    <span className="h-1/2 outline-none focus:outline-none">
                                                        <Dropdown
                                                            menu={{
                                                                items,
                                                                selectable: true,
                                                                onClick: () => handleDeleteThread(i.conversation_id),
                                                            }}
                                                            overlayClassName="dropdown-overlay-container"
                                                        >
                                                            <a onClick={(e) => e.preventDefault()}>
                                                                <SVGIconsList.HorizontalDots className="dark:fill-white fill-black/70" />
                                                            </a>
                                                        </Dropdown>
                                                    </span>
                                                    <span className="dark:text-white/50 text-black/70 text-nowrap">
                                                        {format(new Date(i.updated_time), isToday(new Date(i.updated_time)) ? "hh:mm aa" : "dd/LL/yyyy")}{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="flex flex-row justify-center my-5 dark:text-white text-black/70">
                                    <span>No Threads Found</span>
                                </div>
                            )}

                            {/* END OF INDIVIDUAL THREAD */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ThreadListContainer
