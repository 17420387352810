import React from "react"
import { SVGIconsList } from "./SVGIcons"
import CheckboxComponent from "./CheckboxComponent"
import { format } from "date-fns"
import { DocumentDataType } from "../types/document"
import { Empty } from "antd"

export interface Column {
    title: string
    headerClassName: string
    cellClassName: string
    dataKey: string
    render?: (value: any, row: any) => React.ReactNode
}

interface DynamicTableProps {
    data: any[]
    columns: Column[]
}

const renderFileIcon = (fileType: string) => {
    switch (fileType) {
        case "document":
            return <SVGIconsList.DocxIcon className="fill-blue-500 w-8 h-7 pr-3" />
        case "xlsx":
            return <SVGIconsList.XlsxIcon className="fill-green-500 w-8 h-7 pr-3" />
        case "pdf":
            return <SVGIconsList.PdfIcon className="fill-red-500 w-8 h-7 pr-3" />
        case "folder":
            return <SVGIconsList.ClosedFolderIcon className="w-8 h-7 pr-3 fill-white" />
        default:
            return null
    }
}

const DynamicTable: React.FC<DynamicTableProps> = ({ data, columns }) => {
    return (
        <div className="w-full h-full overflow-y-scroll">
            <table className="w-[95%] mx-auto dark:text-white text-black/70">
                <thead className="top-0 sticky dark:bg-background bg-transparent border-b dark:border-white/20 border-black/30 z-100">
                    <tr>
                        {columns?.map((column, index) => (
                            <th key={index} className={column.headerClassName}>
                                <span className="dark:text-white/50 text-black/50">{column.title}</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="w-full h-full overflow-y-auto p-8">
                    {data?.length > 0 ? data?.map((row, rowIndex) => (
                        <tr key={rowIndex} className="h-16 dark:hover:bg-black/20 hover:bg-black/5">
                            {columns.map((column, colIndex) => (
                                <td key={colIndex} className={column.cellClassName}>
                                    {column.render ? column.render(row[column.dataKey], row) : <span>{row[column.dataKey]}</span>}
                                </td>
                            ))}
                        </tr>
                    )) : <tr>
                        <td colSpan={columns?.length} className="text-center py-20">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                <span className="dark:text-white text-black/50 mt-5 font-light text-lg py-10">No Data Available</span>
                            } />
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
}

const columns: Column[] = [
    {
        title: "Title",
        headerClassName: "w-3/12 h-8 text-left font-light dark:text-white/50 text-black/50 pl-12 ml-[50px] mr-10",
        cellClassName: "font-light",
        dataKey: "name",
        render: (title: string, row: any) => (
            <div className="w-full ml-4 flex flex-row gap-x-4 text-left items-center">
                <CheckboxComponent />
                <div className="w-full flex items-center">
                    {renderFileIcon(row.doc_type)}
                    <span className="w-[15rem] text-lg font-light ml-4 truncate">{title}</span>
                </div>
            </div>
        ),
    },
    {
        title: "Date",
        headerClassName: "w-2/12 h-8 text-left font-light dark:text-white/50 text-black/50",
        cellClassName: "font-light",
        dataKey: "updated_time",
        render: (date: string) => <span>{format(new Date(date), "dd/MM/yyyy")} </span>,
    },
    {
        title: "File Size",
        headerClassName: "w-2/12 h-8 text-left font-light dark:text-white/50 text-black/50",
        cellClassName: "font-light",
        dataKey: "fileSize",
        render: (file_size: string) => !file_size ? "-" : file_size
    },
    {
        title: "Added by",
        headerClassName: "w-3/12 h-8 text-left font-light dark:text-white/50 text-black/50",
        cellClassName: "font-light",
        dataKey: "username",
        render: (addedBy: string, row: any) => (
            <div className="flex items-center">
                <img src={"user_avatar.png"} alt="User Avatar" className="w-6 h-6 rounded-full mr-2" />
                <span className="font-light truncate">{addedBy}</span>
            </div>
        ),
    },
    {
        title: "Action",
        headerClassName: "w-1/12 h-8 text-left font-light dark:text-white/50 text-black/70",
        cellClassName: "font-light",
        dataKey: "action",
        render: () => <SVGIconsList.HorizontalDots className="dark:fill-white fill-black/70 rotate-90" />,
    },
]

const TableComponent = ({ dataSource, columns }: { dataSource: DocumentDataType[], columns: Column[] }) => {
    return <DynamicTable data={dataSource} columns={columns} />
}

export default TableComponent
